import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import { DIALOGS_CLIENT } from 'const'
import React from 'react'
import { Fragment } from 'react'
import { getAuditoriaByClienteListService } from 'services'
import { AuditoriaResponse, IClienteResponse } from 'types'

interface Props {
    open: boolean
    cliente: IClienteResponse | undefined
    handleClose: (dialog: string) => void
}

export const AuditDialog = (props: Props) => {
    const { open, cliente, handleClose } = props
    const [auditoriaList, setAuditoriaList] = React.useState<AuditoriaResponse[]>([])

    const loadAuditoria = async (clienteId: number) => {
        const response = await getAuditoriaByClienteListService(clienteId)
        setAuditoriaList(response)
    }

    React.useEffect(() => {
        if (cliente !== undefined) {
            loadAuditoria(cliente.clienteId)
        }
    }, [cliente])

    return (
        <Fragment>
            {cliente && (
                <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                    <DialogTitle>Información de Auditoría</DialogTitle>
                    <DialogContent>
                        <TableContainer component={Paper}>
                            <Table size="small" sx={{ width: '100%' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Campo</TableCell>
                                        <TableCell>Valor Anterior</TableCell>
                                        <TableCell>Valor Nuevo</TableCell>
                                        <TableCell>Evento</TableCell>
                                        <TableCell>Motivo</TableCell>
                                        <TableCell>Usuario</TableCell>
                                        <TableCell>Fecha</TableCell>
                                    </TableRow>
                                </TableHead>
                                {auditoriaList && (
                                    <Fragment>
                                        <TableBody>
                                            {auditoriaList.map((auditoria) => (
                                                <TableRow key={auditoria.auditoriaId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell component="th" scope="row">
                                                        {auditoria.campo}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {auditoria.valorAnterior}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {auditoria.valorNuevo}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {auditoria.evento}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {auditoria.motivo}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {auditoria.usuario}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {auditoria.inserted}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Fragment>
                                )}
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" onClick={()=>handleClose(DIALOGS_CLIENT.AUDIT_CLIENTE)} sx={{ height: '40px', fontSize: '14px' }}>
                            CANCELAR
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Fragment>
    )
}
