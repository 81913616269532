import axios, { AxiosError } from 'axios'
import { LOCALSTORAGE_KEYS, PATHS } from 'const'
import { AuditoriaResponse, ICheckedItems, IClienteRequest, IClienteResponse, IUploadAPTCRequest, PaginationResponse } from 'types'
import { wrapUploadCSV, wrapUploadJsonAPTC } from 'utils'

type IHeaders = {
    Authorization: string
    Token: string
}
const getItem = (key: string): any | undefined => {
    const value = localStorage.getItem(key)
    try {
        if (value) {
            const json = JSON.parse(value)
            return json
        }
    } catch (error) {
        return undefined
    }
}
const getHeaders = (): IHeaders => {
    const token = getItem(LOCALSTORAGE_KEYS.MARATHON) ?? ''
    return {
        Authorization: `Bearer ${process.env.REACT_APP_SECURITY_API_KEY}`,
        Token: token,
    }
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
})

const setAuthHeaders = (Authorization: string, Token: string) => {
    instance.defaults.headers.common['Authorization'] = Authorization
    instance.defaults.headers.common['Token'] = Token
}

export const getAPTCService = async (empresaId: number, type: string) => {
    const { Authorization, Token } = getHeaders()
    setAuthHeaders(Authorization, Token)
    try {
        const response = await instance.get(PATHS.PANELAPTC_PATH, {
            params: {
                empresaId,
                type,
            },
        })
        return response.data
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}

export const uploadJsonAPTCService = async (uploadAPTCRequest: IUploadAPTCRequest) => {
    const { Authorization, Token } = getHeaders()
    setAuthHeaders(Authorization, Token)
    const formData = wrapUploadJsonAPTC(uploadAPTCRequest)
    try {
        const response = await instance.post(PATHS.PANELAPTC_PATH + '/upload', formData)
        return response
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}
