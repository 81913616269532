import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { IUsuarioState, UsuarioResponse } from 'types'

const initialState = {
    usuario: undefined,
} as IUsuarioState

const UsuarioSlice = createSlice({
    name: 'usuario',
    initialState,
    reducers: {
        setUsuario: (state, action: PayloadAction<UsuarioResponse>) => {
            const usuario = action.payload
            return { ...state, usuario }
        },
        clearUsuario: (state) => {
            state.usuario = undefined
        },
    },
})

export const usuarioReducer = UsuarioSlice.reducer

export const { setUsuario, clearUsuario } = UsuarioSlice.actions

export const getUsuarioSelector = (state: RootState) => state.usuario.usuario
