import { EMPRESA_ID } from 'enums'
import { GeneralLayout } from 'layouts'
import { TermsAndPrivacy } from 'pages'
import { RouteObject } from 'react-router-dom'
import { ROUTES } from 'routes'

export const DocsRoute: RouteObject = {
    path: ROUTES.docs,
    element: <GeneralLayout />,
    children: [
        {
            path: ROUTES.superdeporte + ROUTES.root + ROUTES.terminos,
            element: <TermsAndPrivacy empresa={EMPRESA_ID.SUPERDEPORTE} type={'TC'} />,
        },
        {
            path: ROUTES.equinox + ROUTES.root + ROUTES.terminos,
            element: <TermsAndPrivacy empresa={EMPRESA_ID.EQUINOX} type={'TC'} />,
        },
        {
            path: ROUTES.reebok + ROUTES.root + ROUTES.terminos,
            element: <TermsAndPrivacy empresa={EMPRESA_ID.REEBOK} type={'TC'} />,
        },
        {
            path: ROUTES.adidas + ROUTES.root + ROUTES.terminos,
            element: <TermsAndPrivacy empresa={EMPRESA_ID.ADIDAS} type={'TC'} />,
        },
        {
            path: ROUTES.superdeporte + ROUTES.root + ROUTES.privacidad,
            element: <TermsAndPrivacy empresa={EMPRESA_ID.SUPERDEPORTE} type={'AP'} />,
        },
        {
            path: ROUTES.equinox + ROUTES.root + ROUTES.privacidad,
            element: <TermsAndPrivacy empresa={EMPRESA_ID.EQUINOX} type={'AP'} />,
        },
        {
            path: ROUTES.reebok + ROUTES.root + ROUTES.privacidad,
            element: <TermsAndPrivacy empresa={EMPRESA_ID.REEBOK} type={'AP'} />,
        },
        {
            path: ROUTES.adidas + ROUTES.root + ROUTES.privacidad,
            element: <TermsAndPrivacy empresa={EMPRESA_ID.ADIDAS} type={'AP'} />,
        },
    ],
}
