import { IAlert, ICampañaRequest } from 'types'

export const validateCampañaForm = (data: ICampañaRequest, inicioPromo: Date | null, finPromo: Date | null): IAlert[] => {
    const actualAlerts: IAlert[] = []

    if (data.nombre.trim().length <= 0) {
        actualAlerts.push({ title: 'Nombre', message: 'Ingrese el nombre de la campaña.', severity: 'error' })
    }

    if (data.valorMinimo === 0 || data.valorMinimo < 0) {
        actualAlerts.push({ title: 'Valor mínimo', message: 'El valor mínimo no puede ser 0 o negativo.', severity: 'error' })
    }

    if (data.valorMinimo >= 3000) {
        actualAlerts.push({ title: 'Valor mínimo', message: 'El valor mínimo no puede ser mayor a 3000.', severity: 'error' })
    }

    if (data.descripcion.trim().length <= 0) {
        actualAlerts.push({ title: 'Descripción', message: 'Ingrese una descripción.', severity: 'error' })
    }

    if (data.concepto.trim().length <= 0) {
        actualAlerts.push({ title: 'Concepto', message: 'Ingrese un concepto.', severity: 'error' })
    }
    if (data.usabilidad.trim().length <= 0) {
        actualAlerts.push({ title: 'Usabilidad', message: 'Ingrese la usabilidad.', severity: 'error' })
    }
    if (data.restricciones.trim().length <= 0) {
        actualAlerts.push({ title: 'Restricciones', message: 'Ingrese las restricciones', severity: 'error' })
    }

    if (inicioPromo !== null && finPromo !== null) {
        const fechaLimite = new Date()
        fechaLimite.setFullYear(fechaLimite.getFullYear() - 4)
        if (inicioPromo > finPromo) {
            actualAlerts.push({
                severity: 'error',
                title: 'Inicio Campaña',
                message: 'La fecha de inicio campaña no puede ser posterior a la fecha de fin de la campaña.',
            })
        }
        if (inicioPromo < fechaLimite) {
            actualAlerts.push({
                severity: 'error',
                title: 'Inicio Campaña',
                message: 'La fecha de inicio campaña no puede ser anterior a 4 años atrás.',
            })
        }
    }

    return actualAlerts
}
