import { Accordion, AccordionDetails, AccordionSummary, Box, Icon, Typography } from '@mui/material'
import { Fragment } from 'react'
import { IStructureTC } from 'types'

type Props = {
    termsInfo: IStructureTC
}

export const AcordionTerms = (props: Props) => {
    const { termsInfo } = props

    return (
        <Fragment>
            {termsInfo.terminos.length > 0 ? (
                termsInfo.terminos.map((info, index) => (
                    <Accordion key={index}>
                        <AccordionSummary
                            expandIcon={<Icon>expand_more</Icon>}
                            aria-controls={`panel${index + 1}-content`}
                            id={`panel${index + 1}-header`}
                            style={{ marginBottom: '0' }}
                        >
                            <Typography sx={{ fontWeight: 700 }} dangerouslySetInnerHTML={{ __html: info.name }} />
                        </AccordionSummary>
                        <AccordionDetails style={{ textAlign: 'justify', paddingTop: '0px' }}>
                            {info.terms.map((data, dataIndex) => (
                                <Box key={dataIndex}>
                                    <Typography dangerouslySetInnerHTML={{ __html: data.title }} />
                                    {data.paragraphs.map((paragraph, paIndex) => (
                                        <p
                                            key={paIndex}
                                            dangerouslySetInnerHTML={{ __html: paragraph }}
                                            style={{ marginTop: '0px', overflowWrap: 'break-word', wordWrap: 'break-word', wordBreak: 'break-word' }}
                                        />
                                    ))}
                                </Box>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                ))
            ) : (
                <Typography sx={{ fontWeight: 700 }}>No se encontró información de términos y condiciones</Typography>
            )}

        </Fragment>
    )
}

