import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, LinearProgress, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { AcordionPrivacy, AcordionTerms } from 'components'
import { PAGES } from 'const'
import { EMPRESA_ID, LOADING_OPTION } from 'enums'
import { useLoading } from 'hooks'
import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { getAPTCService } from 'services'
import { IStructureAP, IStructureTC, ITypeAPTC } from 'types'
import { getTitulo } from 'utils'

type TermsAndPrivacyProps = {
    empresa: number
    type: ITypeAPTC
}

export const TermsAndPrivacy: React.FC<TermsAndPrivacyProps> = (props: TermsAndPrivacyProps) => {
    const { empresa, type } = props
    const { loading, handleChangeLoading, handleCloseLoad } = useLoading<LOADING_OPTION>()
    const [infoTerms, setTerms] = React.useState<IStructureTC | null>(null)
    const [infoAP, setAP] = React.useState<IStructureAP | null>(null)
    const getAPTCloading = loading.includes(LOADING_OPTION.GET_APTC_JSON)

    const getAPTCByEmpresaAndType = async () => {
        try {
            handleChangeLoading(LOADING_OPTION.GET_APTC_JSON)
            const response = await getAPTCService(empresa, type)
            if (response) {
                type === 'AP' ? setAP(response) : setTerms(response)
            } else {
                setAP(null)
                setTerms(null)
            }
        } catch (error) {
            console.log("Error")
        } finally {
            handleCloseLoad(LOADING_OPTION.GET_APTC_JSON)
        }
    }

    const navigateToPage = (): void => {
        if (empresa === EMPRESA_ID.SUPERDEPORTE) {
            window.location.assign(PAGES.SUPERDEPORTE_PAGE)
        } else if (empresa === EMPRESA_ID.EQUINOX) {
            window.location.assign(PAGES.EQUINOX_PAGE)
        } else if (empresa === EMPRESA_ID.REEBOK) {
            window.location.assign(PAGES.MEDEPORT_REEBOOK_PAGE)
        } else if (empresa === EMPRESA_ID.ADIDAS) {
            window.location.assign(PAGES.MEDEPORT_ADIDAS_PAGE)
        }
    }

    React.useEffect(() => {
        getAPTCByEmpresaAndType()
    }, [])

    return (
        <Fragment>
            <Box>
                <Helmet>
                    <title>{getTitulo(type, empresa)}</title>
                </Helmet>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    mx: 2,
                    mb: 4,
                }}
            >
                <Box style={{ maxWidth: '40px', margin: '0 auto' }}>
                    <IconButton onClick={() => navigateToPage()}>
                        <ArrowBackIcon />
                    </IconButton>
                </Box>
                <Typography variant="h5" sx={{ fontWeight: '700', fontSize: '25px', textAlign: 'center', paddingBottom: '10px' }}>
                    {getTitulo(type, empresa)}
                </Typography>
                <Fragment>
                    {type === 'AP' ? (
                        infoAP === null ? (
                            <Box sx={{ width: '100%', textAlign: 'center' }}>
                                {getAPTCloading ? (
                                    <>
                                        <LinearProgress color="secondary" sx={{ width: '100%' }} />
                                        <Typography variant="h5" color="initial">Cargando...</Typography>
                                    </>
                                ) : (
                                    <Typography variant="h5" color="initial">No se encontró información</Typography>
                                )}
                            </Box>
                        ) : (
                            <AcordionPrivacy privacyInfo={infoAP} />
                        )
                    ) : (
                        infoTerms === null ? (
                            <Box sx={{ width: '100%', textAlign: 'center' }}>
                                {getAPTCloading ? (
                                    <>
                                        <LinearProgress color="secondary" sx={{ width: '100%' }} />
                                        <Typography variant="h5" color="initial">Cargando...</Typography>
                                    </>
                                ) : (
                                    <Typography variant="h5" color="initial">No se encontró información</Typography>
                                )}
                            </Box>
                        ) : (
                            <AcordionTerms termsInfo={infoTerms} />
                        )
                    )}
                </Fragment>
            </Box>
        </Fragment>
    )
}
