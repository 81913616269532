import EditIcon from '@mui/icons-material/Edit'
import {
    Box,
    Checkbox,
    Icon,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip
} from '@mui/material'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'
import React, { Fragment } from 'react'
import { CampañaPaginationResponse, ICampaña } from 'types'

interface Props {
    page: number
    rowsPerPage: number
    campañas: CampañaPaginationResponse | undefined
    onlyUse: string
    busqueda: string
    inicioPromo: Date | null
    finPromo: Date | null
    handleSetPage: (page: number) => void
    handleSetRowsPerPage: (rowsPerPage: number) => void
    handleGetCampañas: (page: number, rowsPerPage: number, onlyUse: string, busqueda: string, inicioPromo: Date | null, finPromo: Date | null) => Promise<void>
    handleOpenEdit: (campaña: ICampaña) => void
    handleOpenDialogDelete: (campaña: ICampaña) => void
}

export const CampañaTable = (props: Props) => {
    const {
        page,
        rowsPerPage,
        campañas,
        onlyUse,
        busqueda,
        inicioPromo,
        finPromo,
        handleSetPage,
        handleSetRowsPerPage,
        handleGetCampañas,
        handleOpenEdit,
        handleOpenDialogDelete
    } = props


    const handleChangePage = async (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        handleSetPage(newPage)
        await handleGetCampañas(newPage, rowsPerPage, onlyUse, busqueda, inicioPromo, finPromo)
    }

    const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        handleSetRowsPerPage(parseInt(event.target.value, 10))
        handleSetPage(0)
        await handleGetCampañas(0, parseInt(event.target.value, 10), onlyUse, busqueda, inicioPromo, finPromo)
    }


    return (

        <TableContainer component={Paper} sx={{ maxWidth: '100%', mr: { xs: 5, sm: 0 } }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Inicio Campaña</TableCell>
                        <TableCell>Fin Campaña</TableCell>
                        <TableCell>Concepto</TableCell>
                        <TableCell>Restricciones</TableCell>
                        <TableCell>Usabilidad</TableCell>
                        <TableCell>Valor Mínimo</TableCell>
                        <TableCell>Único uso</TableCell>
                        <TableCell align="center" sx={{ minWidth: 100 }}>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                {campañas && (
                    <Fragment>
                        <TableBody>
                            {campañas.result.map((campaña) => (
                                <TableRow key={campaña.campañaId}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {campaña.nombre}
                                    </TableCell>
                                    <TableCell component="th" scope="row" >
                                        {campaña.inicioPromo}
                                    </TableCell>
                                    <TableCell component="th" scope="row" sx={{ paddingX: '2px', textAlign: 'center' }} >
                                        {campaña.finPromo}
                                    </TableCell>
                                    <TableCell component="th" scope="row" sx={{ paddingX: '3px' }}>
                                        {campaña.concepto}
                                    </TableCell>

                                    <TableCell component="th" scope="row" sx={{ paddingX: '3px' }}>
                                        {campaña.restricciones}
                                    </TableCell>
                                    <TableCell component="th" scope="row" sx={{ paddingX: '3px', textAlign: 'center' }}>
                                        {campaña.usabilidad}
                                    </TableCell>
                                    <TableCell component="th" scope="row" sx={{ paddingX: '3px', textAlign: 'center' }}>
                                        {campaña.valorMinimo}
                                    </TableCell>
                                    <TableCell component="th" scope="row" sx={{ paddingX: '3px', textAlign: 'center' }}>
                                        <Checkbox checked={campaña.onlyUse} />
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        <Tooltip title="Editar">
                                            <IconButton onClick={() => handleOpenEdit(campaña)} size="small">
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Eliminar">
                                            <IconButton onClick={() => handleOpenDialogDelete(campaña)} size="small">
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50]}
                                    colSpan={11}
                                    count={campañas.count}
                                    labelRowsPerPage="registros por página"
                                    labelDisplayedRows={(page) => `${page.from} - ${page.to === -1 ? page.count : page.to} de ${page.count}`}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Fragment>
                )}
            </Table>
        </TableContainer>

    )
}
