import { IClienteResponse, IEditFormError } from 'types'
import dayjs from 'dayjs'

const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

export const validateClientEditForm = (data: IClienteResponse): IEditFormError[] => {
    const actualAlerts: IEditFormError[] = []

    if (data.nombres === undefined || data.nombres.trim().length <= 0) {
        actualAlerts.push({ key: 'nombres', message: 'Debe ingresar sus nombres.', typeError: 'error' })
    }

    if (data.apellidos === undefined || data.apellidos.trim().length <= 0) {
        actualAlerts.push({ key: 'apellidos', message: 'Debe ingresar sus apellidos.', typeError: 'error' })
    }

    if (data.genero === undefined) {
        actualAlerts.push({ key: 'genero', message: 'Debe seleccionar un género.', typeError: 'error' })
    }

    if (data.email === undefined || data.email.trim().length <= 0) {
        actualAlerts.push({ key: 'email', message: 'Debe ingresar un email.', typeError: 'error' })
    }

    if (data.email !== undefined && data.email.trim().length > 0) {
        if (!expression.test(data.email)) {
            actualAlerts.push({ key: 'email', message: 'Debe ingresar un email válido.', typeError: 'error' })
        }
    }

    if (data.fechaNacimiento === undefined) {
        actualAlerts.push({ key: 'fechaNacimiento', message: 'Debe selecionar su fecha de nacimiento.', typeError: 'error' })
    } else {
        try {
            const nacimientoDayjs = dayjs(data.fechaNacimiento)
            const nacimientoYear = nacimientoDayjs.year()
            const currentYear = dayjs().year()
            if (nacimientoYear > currentYear) {
                actualAlerts.push({
                    key: 'fechaNacimiento',
                    message: 'La fecha de nacimiento no puede estar en el futuro.',
                    typeError: 'error',
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    if (data.celular === undefined || data.celular.trim().length === 0) {
        actualAlerts.push({ key: 'celular', message: 'Debe ingresar un número celular.', typeError: 'error' })
    }

    if (data.celular !== undefined && data.celular.trim().length !== 0) {
        if (data.celular.trim().length < 9) {
            actualAlerts.push({ key: 'celular', message: 'Número de celular incorrecto.', typeError: 'error' })
        } else if (data.celular.trim().length === 9) {
            if (!/^9/.test(data.celular)) {
                actualAlerts.push({ key: 'celular', message: 'El número debe iniciar con 9', typeError: 'error' })
            }
        } else if (data.celular.trim().length === 10) {
            if (!/^09/.test(data.celular)) {
                actualAlerts.push({ key: 'celular', message: 'El número debe iniciar con 09', typeError: 'error' })
            }
        }
    }

    if (data.ultimoMotivo === undefined || data.ultimoMotivo === null || data.ultimoMotivo.trim().length <= 0) {
        actualAlerts.push({ key: 'ultimoMotivo', message: 'Debe ingresar un motivo para la modificación.', typeError: 'error' })
    }

    return actualAlerts
}
