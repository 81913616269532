import { LoadingButton } from "@mui/lab"
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Grid,
    TextField
} from "@mui/material"
import React, { useState } from "react"
import { ICampaña } from "types"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { parseISO } from 'date-fns'
import esLocale from 'date-fns/locale/es' 
import { ICampañaRequest } from "types/request/campaña"
import { validateCampañaForm } from "utils"

type Props = {
    open: boolean
    campaña: ICampaña | undefined
    handleClose: () => void
    handleCreateCampaña: (campaña: ICampañaRequest) => Promise<void>
    handleUpdateCampaña: (campaña: ICampañaRequest) => Promise<void>
    addErrorMessage: (message: string) => void
    handleCloseSnackbar: () => void
    loadingForm: boolean
}

const initialCampaña: ICampaña = {
    campañaId: 0,
    nombre: '',
    valorMinimo: 0,
    onlyUse: true,
    descripcion: '',
    concepto: '',
    usabilidad: '',
    restricciones: '',
    inicioPromo: '',
    finPromo: ''
}

export const CampañaForm = (props: Props) => {
    const {
        campaña,
        open,
        handleClose,
        handleCreateCampaña,
        handleUpdateCampaña,
        loadingForm,
        handleCloseSnackbar,
        addErrorMessage
    } = props
    const [campañaState, setCampañaState] = useState<ICampaña>(initialCampaña)
    const [inicioPromo, setInicioPromo] = React.useState<Date | null>(new Date())
    const [finPromo, setFinPromo] = React.useState<Date | null>(new Date())

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setCampañaState(prevState => ({
            ...prevState,
            [name]: name === 'onlyUse' ? e.target.checked : value
        }))
    }

    const handleSubmit = async (campañaState: ICampaña) => {
        const campañaRequest: ICampañaRequest = {
            ...campañaState,
            inicioPromo: inicioPromo!,
            finPromo: finPromo!
        }
        const actualAlerts = validateCampañaForm(campañaRequest, inicioPromo, finPromo)
        try {
            if (actualAlerts.length > 0) {
                addErrorMessage(actualAlerts[0].message)
            } else {
                handleCloseSnackbar()
                if (campañaState.campañaId === 0) {
                    await handleCreateCampaña(campañaRequest)
                } else {
                    await handleUpdateCampaña(campañaRequest)
                }
                handleClose()
            }
        } catch (error) {
            console.error('Error al procesar la campaña:', error)
        }
    }

    const onSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        await handleSubmit(campañaState)
    }

    React.useEffect(() => {
        if (campaña) {
            setCampañaState(campaña)
            setInicioPromo(parseISO(campaña.inicioPromo))
            setFinPromo(parseISO(campaña.finPromo))
        } else {
            setCampañaState(initialCampaña)
        }
    }, [campaña])

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>{campaña ? 'Editar Información Campaña' : 'Crear Nueva Campaña'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {campaña
                            ? 'Toda edición de información será guardada como registro de auditoría.'
                            : 'Por favor, completa la información para crear una nueva campaña.'}
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                label="Nombre"
                                name="nombre"
                                fullWidth
                                value={campañaState.nombre}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                label="Valor Mínimo"
                                name="valorMinimo"
                                type="number"
                                fullWidth
                                value={campañaState.valorMinimo}
                                onChange={handleChange}
                                required
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                        max: 3000
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                label="Descripción"
                                name="descripcion"
                                fullWidth
                                value={campañaState.descripcion}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                label="Concepto"
                                name="concepto"
                                fullWidth
                                value={campañaState.concepto}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                label="Usabilidad"
                                name="usabilidad"
                                fullWidth
                                value={campañaState.usabilidad}
                                onChange={handleChange}
                                multiline
                                rows={2}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                label="Restricciones"
                                name="restricciones"
                                fullWidth
                                value={campañaState.restricciones}
                                onChange={handleChange}
                                multiline
                                rows={2}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                <DatePicker
                                    label="Inicio Campaña"
                                    value={inicioPromo}
                                    onChange={(newValue) => setInicioPromo(newValue)}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                <DatePicker
                                    label="Fin Campaña"
                                    value={finPromo}
                                    onChange={(newValue) => setFinPromo(newValue)}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} sx={{ marginLeft: 2.5 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={campañaState.onlyUse}
                                        onChange={handleChange}
                                        name="onlyUse"
                                    />
                                }
                                label="Único uso"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button sx={{
                        height: '40px',
                        width: '160px',
                        borderRadius: '2px',
                        fontSize: '16px',
                        '& .MuiLoadingButton-loadingIndicator': {
                            color: 'white',
                        },
                    }} onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <LoadingButton
                        sx={{
                            height: '40px',
                            width: '160px',
                            borderRadius: '2px',
                            fontSize: '16px',
                            '& .MuiLoadingButton-loadingIndicator': {
                                color: 'white',
                            },
                        }}
                        loading={loadingForm}
                        disabled={loadingForm}
                        onClick={async (event) => {
                            await onSubmit(event)
                        }}
                        type="submit" color="primary">
                        {campaña ? 'Guardar Cambios' : 'Crear Campaña'}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )

}