import ClearIcon from '@mui/icons-material/Clear'
import DescriptionIcon from '@mui/icons-material/Description'
import SearchIcon from '@mui/icons-material/Search'
import { LoadingButton } from '@mui/lab'
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Collapse,
    FormControl,
    Icon,
    IconButton,
    InputAdornment,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Tooltip,
    Typography
} from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { DIALOGS_CLIENT } from 'const'
import dayjs from 'dayjs'
import { LOADING_OPTION } from 'enums'
import { getEmpresaListSelector, getUsuarioSelector } from 'features'
import { saveAs } from 'file-saver'
import { AuditDialog, ClientesTable, Dashboard, EditClienteDialog, SendEmailDialog } from 'pages'
import React, { useState } from 'react'
import { getClienteListService, getClienteReporteService } from 'services'
import { ICheckedItems, IClienteResponse, IEmpresa, PaginationResponse } from 'types'
import { useLoading } from '../../../hooks/useLoading'
import { CONDITIONS, INITIAL_VALUES_FILTER } from 'mock'


export const Clientes = () => {
    const [busqueda, setBusqueda] = React.useState<string>()
    const [empresa, setEmpresa] = React.useState<number>(0)
    const [clientes, setClientes] = React.useState<PaginationResponse>()
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [openEdit, setOpenEdit] = React.useState<boolean>(false)
    const [openAudit, setOpenAudit] = React.useState<boolean>(false)
    const [openSendEmail, setOpenSendEmail] = React.useState<boolean>(false)
    const [showTooltip, setShowTooltip] = useState<boolean>(false)
    const [checkedItems, setCheckedItems] = useState<ICheckedItems>(INITIAL_VALUES_FILTER)
    const [selectedCliente, setSelectedCliente] = React.useState<IClienteResponse>()
    const empresaList = useAppSelector(getEmpresaListSelector)
    const usuario = useAppSelector(getUsuarioSelector)
    const [firstLoad, setFirstLoad] = React.useState<boolean>(true)
    const [openFilters, setOpenFilters] = React.useState<boolean>(true)
    const { loading, handleChangeLoading, handleCloseLoad } = useLoading<LOADING_OPTION>()

    const loadingClients = loading.includes(LOADING_OPTION.LOADING_CLIENTS)
    const loadingSearch = loading.includes(LOADING_OPTION.SEARCH_CLIENTS)
    const loadingClearSearch = loading.includes(LOADING_OPTION.CLEAR_SEARCH_CLIENTS)
    const loadingReport = loading.includes(LOADING_OPTION.GENERATE_REPORT)

    const handleChangeEmpresa = (empresas: IEmpresa[]) => {
        if (empresas.length > 0) {
            setEmpresa(empresas[0].id)
        } else {
            setEmpresa(0)
        }
    }
    const filteredEmpresas = React.useMemo(() => {
        const validEmpresas: IEmpresa[] = []
        if (usuario !== undefined) {
            if (usuario?.permisoSuperdeporte && empresaList[0]) {
                validEmpresas.push(empresaList[0])
            }
            if (usuario?.permisoMedeportAdidas && empresaList[1]) {
                validEmpresas.push(empresaList[1])
            }
            if (usuario?.permisoMedeportReebok && empresaList[2]) {
                validEmpresas.push(empresaList[2])
            }
            if (usuario?.permisoEquinox && empresaList[3]) {
                validEmpresas.push(empresaList[3])
            }
        }
        handleChangeEmpresa(validEmpresas)
        return validEmpresas
    }, [empresaList, usuario])

    const loadingClientes = React.useMemo(() => {
        return loadingClients || clientes === undefined
    }, [clientes, loadingClients])

    const handleGetClientes = async (rowsPerPage: number, page: number, empresa: number, filtros: ICheckedItems, busqueda?: string) => {
        try {
            handleChangeLoading(LOADING_OPTION.LOADING_CLIENTS)
            const clientes = await getClienteListService(rowsPerPage, page, empresa, filtros, busqueda !== '' ? busqueda : undefined,)
            setClientes(clientes)
        } catch (error) {
            console.log("Error", error)
        } finally {
            handleCloseLoad(LOADING_OPTION.LOADING_CLIENTS)
        }
    }

    const hadleResetFilters = (INITIAL_VALUES_FILTER: ICheckedItems) => {
        setCheckedItems(INITIAL_VALUES_FILTER)

    }

    const handleSetPage = (_page: number) => {
        setPage(_page)
    }

    const handleSetRowsPerPage = (_rowsPerPage: number) => {
        setRowsPerPage(_rowsPerPage)
    }

    const handleOpenDialog = (cliente: IClienteResponse, dialog: string) => {
        if (dialog === DIALOGS_CLIENT.EDIT_CLIENTE) {
            setOpenEdit(true)
        } else if (dialog === DIALOGS_CLIENT.AUDIT_CLIENTE) {
            setOpenAudit(true)
        } else if (dialog === DIALOGS_CLIENT.SEND_EMAIL) {
            setOpenSendEmail(true)
        }
        setSelectedCliente(cliente)
    }

    const handleCloseDialog = (dialog: string) => {
        if (dialog === DIALOGS_CLIENT.EDIT_CLIENTE) {
            setOpenEdit(false)
            handleGetClientes(rowsPerPage, page, empresa, checkedItems, busqueda)
        } else if (dialog === DIALOGS_CLIENT.AUDIT_CLIENTE) {
            setOpenAudit(false)
        } else if (dialog === DIALOGS_CLIENT.SEND_EMAIL) {
            setOpenSendEmail(false)
        }
        setSelectedCliente(undefined)
    }

    const handleSetSelectedCliente = (cliente: IClienteResponse) => {
        setSelectedCliente(cliente)
    }

    const handleGetReporteClientes = async (empresa: number, filtros: ICheckedItems, busqueda?: string) => {
        try {
            handleChangeLoading(LOADING_OPTION.GENERATE_REPORT)
            const bytes = await getClienteReporteService(empresa, filtros, busqueda)
            const timestamp = dayjs(new Date()).format('YYYYMMDDhhmmss')
            saveAs(bytes, 'reporte' + timestamp + '.csv')
        } catch (error) {
            console.log("Error", error)
        } finally {
            handleCloseLoad(LOADING_OPTION.GENERATE_REPORT)
        }
    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target
        setCheckedItems(prev => ({
            ...prev,
            [name]: checked
        }))
    }


    const handleSelectAllToggle = () => {
        const allSelected = Object.values(checkedItems).every(Boolean)
        const newCheckedItems = Object.keys(checkedItems).reduce((acc, key) => ({
            ...acc,
            [key]: !allSelected
        }), {} as ICheckedItems)
        setCheckedItems(newCheckedItems)
    }

    const allConditionsSelected = Object.values(checkedItems).every(Boolean)


    const handleKeyUp = async (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            if (busqueda !== undefined || busqueda === '') {
                await handleSearch()
            } else {
                await hanldeClearSearch()
            }
        }
    }

    const handleSearch = async () => {
        try {
            handleChangeLoading(LOADING_OPTION.SEARCH_CLIENTS)
            handleSetPage(0)
            await handleGetClientes(rowsPerPage, 0, empresa, checkedItems, busqueda)
        } catch (error) {
            console.log("Error", error)
        } finally {
            handleCloseLoad(LOADING_OPTION.SEARCH_CLIENTS)
        }
    }

    const hanldeClearSearch = async () => {
        try {
            handleChangeLoading(LOADING_OPTION.CLEAR_SEARCH_CLIENTS)
            setBusqueda('')
            const empresaSelectDefault = filteredEmpresas.length > 0 ? filteredEmpresas[0].id : 0
            setEmpresa(empresaSelectDefault)
            const filtersDefault = INITIAL_VALUES_FILTER
            hadleResetFilters(filtersDefault)
            setOpenFilters(true)
            await handleGetClientes(rowsPerPage, 0, empresaSelectDefault, filtersDefault)
        } catch (error) {
            console.log("Error", error)
        } finally {
            handleCloseLoad(LOADING_OPTION.CLEAR_SEARCH_CLIENTS)
        }
    }

    React.useEffect(() => {
        //? Primera y única carga al inicio
        if (empresa && firstLoad) {
            handleGetClientes(rowsPerPage, page, empresa, checkedItems, busqueda)
            setFirstLoad(false)
        }
    }, [empresa, firstLoad])

    return (
        <Dashboard title="Clientes">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    width: '100%',
                    gap: 1.2,
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        gap: 1.4,
                        width: '100%'
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        gap: 1.7,
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }}>

                        <Tooltip
                            title={
                                <Typography sx={{ fontSize: '0.85rem' }}>
                                    Puedes buscar por nombre, apellido, número de documento o email
                                </Typography>
                            }
                            open={(busqueda === '' || busqueda === undefined) && showTooltip}
                            placement="top">
                            <TextField
                                variant="outlined"
                                size="small"
                                placeholder="Buscar...."
                                autoComplete="off"
                                sx={{ width: '340px' }}
                                value={busqueda}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setBusqueda(event.target.value)
                                }}
                                onKeyUp={async (event) => { await handleKeyUp(event) }}
                                onMouseEnter={() => setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Borrar búsqueda">
                                                <IconButton
                                                    onClick={() => {
                                                        setBusqueda('')
                                                    }}
                                                    edge="end"
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Tooltip>

                        <LoadingButton
                            variant="outlined"
                            disabled={loadingClearSearch}
                            loading={loadingClearSearch}
                            startIcon={<Icon sx={{ color: 'white' }}>cached</Icon>}
                            sx={{
                                height: '40px', fontSize: '12px', width: '130px',
                                '& .MuiLoadingButton-loadingIndicator': {
                                    color: 'white',
                                },
                            }}
                            onClick={async () => {
                                await hanldeClearSearch()
                            }}
                        >
                            Resetear Filtros
                        </LoadingButton>

                        <LoadingButton
                            variant="outlined"
                            disabled={loadingSearch}
                            loading={loadingSearch}
                            startIcon={<SearchIcon sx={{ ml: 1 }} />}
                            sx={{
                                height: '40px',
                                fontSize: '13px',
                                width: '125px',
                                '& .MuiLoadingButton-loadingIndicator': {
                                    color: 'white',
                                },
                            }}
                            onClick={async () => {
                                await handleSearch()
                            }}
                        >
                            Buscar
                        </LoadingButton>

                        <Button
                            size="small"
                            disableElevation
                            sx={{
                                height: '35px',
                                fontSize: '13px',
                                width: '125px',
                                color: '#5D6363',
                                backgroundColor: 'transparent',
                                '&:hover': {
                                    backgroundColor: '#E0E1E0'
                                },
                                borderRadius: 2
                            }}
                            onClick={() => setOpenFilters(current => !current)}
                        >
                            <Icon
                                fontSize="small"
                                sx={{ marginRight: 1 }}
                            >
                                {openFilters ? "expand_less" : "expand_more"}</Icon>
                            {openFilters ? "Menos filtros" : "Más filtros"}
                        </Button>
                    </Box>

                    <Collapse in={openFilters}
                        timeout="auto"
                        unmountOnExit
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            border: '1px solid #C4C4C4',
                            borderRadius: '5px',
                            px: 2,
                            transition: 'height 0.7s ease'
                        }}>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1.5,
                            py: 2
                        }}>

                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>

                                <FormControl size="small">
                                    <InputLabel sx={{ color: '#C4C4C4' }}>Empresa</InputLabel>
                                    <Select
                                        value={empresa !== undefined ? empresa.toString() : ''}
                                        onChange={async (event: SelectChangeEvent) => {
                                            const empresaSelected = Number.parseInt(event.target.value)
                                            setEmpresa(empresaSelected)
                                            await handleGetClientes(rowsPerPage, page, empresaSelected, checkedItems, busqueda)
                                        }}
                                        sx={{ width: '240px', borderRadius: '0px' }}
                                        label="Empresa"
                                    >
                                        {
                                            filteredEmpresas.map((empresa) => (
                                                empresa ? (
                                                    <MenuItem key={empresa.id} value={empresa.id}>
                                                        {empresa.nombre}
                                                    </MenuItem>
                                                ) : null
                                            ))}
                                    </Select>
                                </FormControl>

                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '360px'
                                }}>
                                    <Autocomplete
                                        multiple
                                        options={CONDITIONS}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.name}
                                        fullWidth
                                        value={CONDITIONS.filter(c => checkedItems[c.conditionId as keyof ICheckedItems])}
                                        limitTags={2}
                                        size="small"
                                        onChange={(_, selectedValue) => {
                                            const newCheckedItems = CONDITIONS.reduce((acc, curr) => ({
                                                ...acc,
                                                [curr.conditionId]: selectedValue.some(s => s.conditionId === curr.conditionId),
                                            }), {} as ICheckedItems)
                                            setCheckedItems(newCheckedItems)
                                        }}
                                        renderOption={(props, option) => (
                                            <li {...props}>
                                                <Checkbox
                                                    checked={checkedItems[option.conditionId as keyof ICheckedItems]}
                                                    size="small"
                                                    onChange={(e) => handleCheckboxChange({
                                                        target: {
                                                            name: option.conditionId,
                                                            checked: e.target.checked
                                                        }
                                                    } as React.ChangeEvent<HTMLInputElement>)}
                                                />
                                                <Typography variant="body2">{option.name}</Typography>
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                fullWidth
                                                label="Filtro"
                                                autoComplete="off"
                                            />
                                        )}
                                    />
                                    <Tooltip arrow title={allConditionsSelected ? "Quitar todos" : "Seleccionar todos"}>
                                        <IconButton onClick={handleSelectAllToggle}>
                                            <Icon fontSize="small">
                                                {allConditionsSelected ? "indeterminate_check_box" : "check_box_outline_blank"}
                                            </Icon>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>

                        </Box>

                    </Collapse>

                </Box>

                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: {
                            xs: 'center',
                            md: 'flex-end'
                        },
                    }}
                >
                    <LoadingButton
                        disabled={loadingReport}
                        loading={loadingReport}
                        variant="outlined"
                        startIcon={<DescriptionIcon sx={{ ml: 1 }} />}
                        sx={{
                            height: '40px',
                            width: '160px',
                            borderRadius: '2px',
                            fontSize: '12px',
                            '& .MuiLoadingButton-loadingIndicator': {
                                color: 'white',
                            },
                        }}
                        onClick={async () => await handleGetReporteClientes(empresa, checkedItems, busqueda)}
                    >
                        Generar CSV
                    </LoadingButton>
                </Box>
            </Box>



            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                {
                    loadingClientes ? (
                        <LinearProgress color="secondary" sx={{ width: '100%' }} />
                    ) : (
                        <ClientesTable
                            busqueda={busqueda}
                            empresa={empresa}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            handleSetPage={handleSetPage}
                            handleSetRowsPerPage={handleSetRowsPerPage}
                            clientes={clientes}
                            filtros={checkedItems}
                            handleGetClientes={handleGetClientes}
                            handleOpenDialog={handleOpenDialog}
                        />
                    )
                }

            </Box>
            <EditClienteDialog open={openEdit} cliente={selectedCliente} handleClose={handleCloseDialog} handleSetSelectedCliente={handleSetSelectedCliente} />
            <AuditDialog open={openAudit} cliente={selectedCliente} handleClose={handleCloseDialog} />
            <SendEmailDialog open={openSendEmail} cliente={selectedCliente} handleClose={handleCloseDialog} />
        </Dashboard>
    )
}
