import { IUploadAPTCRequest } from 'types'

export const wrapUploadCSV = (file: File, empresaId: number, usuarioId: number): FormData => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('empresaId', String(empresaId))
    formData.append('usuarioId', String(usuarioId))
    return formData
}

export const wrapUploadJsonAPTC = (uploadAPTCRequest: IUploadAPTCRequest): FormData => {
    const formData = new FormData()
    formData.append('file', uploadAPTCRequest.file)
    formData.append('empresaId', String(uploadAPTCRequest.empresaId))
    formData.append('type', String(uploadAPTCRequest.type))
    return formData
}
