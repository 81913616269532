
import { LoadingButton, } from '@mui/lab'
import {
    Alert,
    AlertColor,
    Box,
    Button,
    Collapse,
    FormControl,
    Icon,
    IconButton,
    InputAdornment,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    SelectChangeEvent,
    Snackbar,
    TextField,
    Tooltip,
    Typography
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { enGB } from 'date-fns/locale'
import { LOADING_OPTION } from 'enums'
import { CampañaDelete, CampañaForm, CampañaTable, Dashboard } from 'pages'
import React, { useState } from 'react'
import { deleteCampañaService, getCampañasPaginateService, insertCampañaService, updateCampañaService } from 'services'
import { CampañaPaginationResponse, IAlert, ICampaña, ICampañaRequest } from 'types'
import { useLoading } from '../../../hooks/useLoading'



enum FILTER_TYPE {
    NONE = 'none',
    DATE_INIT = 'dateInit',
    DATE_EXPIRATION = 'dateExpiration',
    DATE_BETWEEN = 'dateBetween',
}

enum FILTER_ONLY_USE {
    ALL = 'todos',
    ONLY_USE = 'only',
    NO_ONLY_USE = 'noOnly',
}

type SelectionFilterOption = {
    filterType: FILTER_TYPE
    label: string
}
type IOnlyUseFilterOption = {
    filterType: FILTER_ONLY_USE
    label: string
}
const selectionFilterOptions: SelectionFilterOption[] = [
    {
        filterType: FILTER_TYPE.NONE,
        label: 'Ninguno',
    },
    {
        filterType: FILTER_TYPE.DATE_INIT,
        label: 'Inicio Promo ',
    },
    {
        filterType: FILTER_TYPE.DATE_EXPIRATION,
        label: 'Fin Promo',
    },
    {
        filterType: FILTER_TYPE.DATE_BETWEEN,
        label: ' Inicio y Fin Promo',
    },
]

const optionsOnlyUse: IOnlyUseFilterOption[] = [
    {
        filterType: FILTER_ONLY_USE.ALL,
        label: 'Todos',
    },
    {
        filterType: FILTER_ONLY_USE.ONLY_USE,
        label: 'Único',
    },
    {
        filterType: FILTER_ONLY_USE.NO_ONLY_USE,
        label: 'No Único',
    },
]

export const Campaña = () => {
    const [busqueda, setBusqueda] = React.useState<string>('')
    const [campañas, setCampañas] = React.useState<CampañaPaginationResponse>()
    const [page, setPage] = React.useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [openForm, setOpenForm] = React.useState<boolean>(false)
    const [openDelete, setOpenDelete] = React.useState<boolean>(false)
    const [onlyUse, setOnlyUse] = React.useState<FILTER_ONLY_USE>(FILTER_ONLY_USE.ALL)
    const [showTooltip, setShowTooltip] = useState<boolean>(false)
    const [selectedCampaña, setSelectedCampaña] = React.useState<ICampaña>()
    const [inicioPromo, setInicioPromo] = React.useState<Date | null>(null)
    const [finPromo, setFinPromo] = React.useState<Date | null>(null)
    const [openFilters, setOpenFilters] = React.useState<boolean>(true)
    const [filterType, setFilterType] = React.useState<FILTER_TYPE>(FILTER_TYPE.NONE)
    const { loading, handleChangeLoading, handleCloseLoad } = useLoading<LOADING_OPTION>()
    const [alert, setAlert] = React.useState<IAlert>()

    const loadingGetCampañas = loading.includes(LOADING_OPTION.LOADING_CAMPAÑAS)
    const loadingDeleteCampañas = loading.includes(LOADING_OPTION.DELETE_CAMPAÑA)
    const loadingForm = loading.includes(LOADING_OPTION.LOADING_FORM_CAMPAÑA)
    const loadingClearCampañas = loading.includes(LOADING_OPTION.CLEAR_SEARCH_CAMPAÑAS)
    const loadingSearch = loading.includes(LOADING_OPTION.SEARCH_CAMPAÑAS)



    const loadingClientes = React.useMemo(() => {
        return loadingGetCampañas || campañas === undefined
    }, [campañas, loadingGetCampañas])

    const addSuccesMessage = (message: string) => {
        setAlert({
            severity: 'success',
            title: 'Éxito',
            message
        })
    }

    const addErrorMessage = (message: string) => {
        setAlert({
            severity: 'error',
            title: 'Error',
            message
        })
    }

    const handleGetCampañas = async (
        page: number,
        rowsPerPage: number,
        onlyUse: string,
        busqueda: string,
        inicioPromo: Date | null,
        finPromo: Date | null
    ) => {
        try {

            handleChangeLoading(LOADING_OPTION.LOADING_CAMPAÑAS)
            const campañas = await getCampañasPaginateService(
                page,
                rowsPerPage,
                onlyUse,
                busqueda,
                inicioPromo,
                finPromo
            )
            setCampañas(campañas)
        } catch (error) {
            console.log("Error", error)
        } finally {
            handleCloseLoad(LOADING_OPTION.LOADING_CAMPAÑAS)
        }
    }

    const handleOpenEdit = (campaña: ICampaña) => {
        setOpenForm(true)
        setSelectedCampaña(campaña)
    }

    const handleOpenNewCampaña = () => {
        setOpenForm(true)
    }


    const handleCloseDialogDelete = () => {
        setOpenDelete(false)
        setSelectedCampaña(undefined)
    }

    const handleOpenDialogDelete = (campaña: ICampaña) => {
        setOpenDelete(true)
        setSelectedCampaña(campaña)
    }


    const handleCloseSnackbar = () => {
        setAlert(undefined)
    }

    const handleDeleteCampaña = async () => {
        try {
            handleChangeLoading(LOADING_OPTION.DELETE_CAMPAÑA)
            const response = await deleteCampañaService(selectedCampaña!.campañaId)
            if (response.status === 200) {
                addSuccesMessage("Campaña Eliminada")
                await handleGetCampañas(0, rowsPerPage, onlyUse, '', null, null)
            } else {
                addErrorMessage("Error no se pudo eliminar la campaña")
            }
        } catch (error) {
            console.log("Error eliminando", error)
        } finally {
            handleCloseDialogDelete()
            handleCloseLoad(LOADING_OPTION.DELETE_CAMPAÑA)
        }

    }

    const handleSetPage = (_page: number) => {
        setPage(_page)
    }

    const handleSetRowsPerPage = (_rowsPerPage: number) => {
        setRowsPerPage(_rowsPerPage)
    }


    const handleKeyUp = async (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            if (busqueda !== undefined || busqueda === '') {
                await handleSearch()
            } else {
                await hanldeClearSearch()
            }
        }
    }

    const handleSearch = async () => {
        try {
            handleChangeLoading(LOADING_OPTION.SEARCH_CAMPAÑAS)
            handleSetPage(0)
            await handleGetCampañas(0, rowsPerPage, onlyUse, busqueda, inicioPromo, finPromo)
        } catch (error) {
            console.log("Error", error)
        } finally {
            handleCloseLoad(LOADING_OPTION.SEARCH_CAMPAÑAS)
        }
    }

    const hanldeClearSearch = async () => {
        try {
            handleChangeLoading(LOADING_OPTION.CLEAR_SEARCH_CAMPAÑAS)
            setBusqueda('')
            setOpenFilters(true)
            setFilterType(FILTER_TYPE.NONE)
            await handleGetCampañas(0, rowsPerPage, onlyUse, '', null, null)
        } catch (error) {
            console.log("Error", error)
        } finally {
            handleCloseLoad(LOADING_OPTION.CLEAR_SEARCH_CAMPAÑAS)
        }
    }

    const handleCreateCampaña = async (campaña: ICampañaRequest) => {
        try {
            handleChangeLoading(LOADING_OPTION.LOADING_FORM_CAMPAÑA)
            const response = await insertCampañaService(campaña)
            if (response.status === 201) {
                addSuccesMessage("Campaña Creada")
                await handleGetCampañas(0, rowsPerPage, onlyUse, '', null, null)
            } else {
                addErrorMessage("No se puedo registrar la campaña")
            }
        } catch (error) {
            console.log("Error", error)
        } finally {
            handleCloseLoad(LOADING_OPTION.LOADING_FORM_CAMPAÑA)
            handleClose()
        }
    }

    const handleUpdateCampaña = async (campaña: ICampañaRequest) => {
        try {
            handleChangeLoading(LOADING_OPTION.LOADING_FORM_CAMPAÑA)
            const response = await updateCampañaService(campaña)
            if (response.status === 200) {
                addSuccesMessage("Campaña Actualizada")
                await handleGetCampañas(0, rowsPerPage, onlyUse, '', null, null)
            } else {
                addErrorMessage("No se puedo actualizar la campaña")
            }
        } catch (error) {
            console.log("Error", error)
        } finally {
            handleCloseLoad(LOADING_OPTION.LOADING_FORM_CAMPAÑA)
            handleClose()
        }

    }

    const handleSearchByDate = async (inicioPromo: Date | null, finPromo: Date | null) => {
        if (filterType === FILTER_TYPE.DATE_INIT) {
            await handleGetCampañas(
                page,
                rowsPerPage,
                onlyUse,
                busqueda,
                inicioPromo !== null ? inicioPromo : null,
                null
            )
        } else if (filterType === FILTER_TYPE.DATE_EXPIRATION) {
            await handleGetCampañas(
                page,
                rowsPerPage,
                onlyUse,
                busqueda,
                null,
                finPromo !== null ? finPromo : null
            )
        } else if (filterType === FILTER_TYPE.DATE_BETWEEN) {
            await handleGetCampañas(
                page,
                rowsPerPage,
                onlyUse,
                busqueda,
                inicioPromo !== null ? inicioPromo : null,
                finPromo !== null ? finPromo : null
            )
        }
    }

    const handleChangeOnlyUse = async (newValue: FILTER_ONLY_USE) => {
        setOnlyUse(newValue)
        await handleGetCampañas(
            page,
            rowsPerPage,
            newValue,
            busqueda,
            inicioPromo,
            finPromo
        )
    }

    const handleChange = async (event: SelectChangeEvent) => {
        const filterTypeSelected = event.target.value as FILTER_TYPE
        setFilterType(filterTypeSelected)
        await handleGetCampañas(0, rowsPerPage, onlyUse, busqueda, null, null)
        setInicioPromo(null)
        setFinPromo(null)
    }

    const handleClose = () => {
        setOpenForm(false)
        setSelectedCampaña(undefined)
    }



    React.useEffect(() => {
        handleGetCampañas(
            page,
            rowsPerPage,
            onlyUse,
            busqueda,
            inicioPromo,
            finPromo)
    }, [])

    return (
        <Dashboard title="Campañas">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    width: '100%',
                    gap: 1.2,
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        gap: 1.4,
                        width: '100%'
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        gap: 1.7,
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }}>

                        <Tooltip
                            title={
                                <Typography sx={{ fontSize: '0.85rem' }}>
                                    Puedes buscar por nombre, descripción o restrición
                                </Typography>
                            }
                            open={(busqueda === '' || busqueda === undefined) && showTooltip}
                            placement="top">
                            <TextField
                                variant="outlined"
                                size="small"
                                placeholder="Buscar...."
                                autoComplete="off"
                                sx={{ width: '340px' }}
                                value={busqueda}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setBusqueda(event.target.value)
                                }}
                                onKeyUp={async (event) => { await handleKeyUp(event) }}
                                onMouseEnter={() => setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Borrar búsqueda">
                                                <IconButton
                                                    onClick={() => {
                                                        setBusqueda('')
                                                    }}
                                                    edge="end"
                                                >
                                                    <Icon>clear</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Tooltip>

                        <LoadingButton
                            variant="outlined"
                            disabled={loadingClearCampañas}
                            loading={loadingClearCampañas}
                            startIcon={<Icon sx={{ color: 'white' }}>cached</Icon>}
                            sx={{
                                height: '40px', fontSize: '12px', width: '130px',
                                '& .MuiLoadingButton-loadingIndicator': {
                                    color: 'white',
                                },
                            }}
                            onClick={async () => {
                                await hanldeClearSearch()
                            }}
                        >
                            Resetear Filtros
                        </LoadingButton>

                        <LoadingButton
                            variant="outlined"
                            disabled={loadingSearch}
                            loading={loadingSearch}
                            startIcon={<Icon sx={{ ml: 1 }}>search</Icon>}
                            sx={{
                                height: '40px',
                                fontSize: '13px',
                                width: '125px',
                                '& .MuiLoadingButton-loadingIndicator': {
                                    color: 'white',
                                },
                            }}
                            onClick={async () => {
                                await handleSearch()
                            }}
                        >
                            Buscar
                        </LoadingButton>

                        <Button
                            size="small"
                            disableElevation
                            sx={{
                                height: '35px',
                                fontSize: '13px',
                                width: '125px',
                                color: '#5D6363',
                                backgroundColor: 'transparent',
                                '&:hover': {
                                    backgroundColor: '#E0E1E0'
                                },
                                borderRadius: 2
                            }}
                            onClick={() => setOpenFilters(current => !current)}
                        >
                            <Icon
                                fontSize="small"
                                sx={{ marginRight: 1 }}
                            >
                                {openFilters ? "expand_less" : "expand_more"}</Icon>
                            {openFilters ? "Menos filtros" : "Más filtros"}
                        </Button>
                    </Box>

                    <Collapse in={openFilters}
                        timeout="auto"
                        unmountOnExit
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            border: '1px solid #C4C4C4',
                            borderRadius: '5px',
                            px: 2,
                            transition: 'height 0.7s ease'
                        }}>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1.5,
                            py: 2
                        }}>


                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: filterType === FILTER_TYPE.NONE ? 'flex-end' : 'space-between',
                                    gap: 1.3,
                                }}
                            >
                                <Box sx={{
                                    display: filterType === FILTER_TYPE.NONE ? 'none' : 'block',
                                }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                                        {
                                            filterType === FILTER_TYPE.DATE_INIT && (
                                                <DatePicker
                                                    sx={{
                                                        '& .PrivateDatePickerRoot-picker': {
                                                            fontSize: '1rem',
                                                            overflow: 'hidden',
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            fontSize: '1rem',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '46px',
                                                            fontSize: '0.9rem',
                                                        },
                                                    }}
                                                    label="Fecha Inicio"
                                                    value={inicioPromo}
                                                    onChange={async (newValue) => {
                                                        await handleSearchByDate(newValue, null)
                                                        setInicioPromo(newValue)
                                                    }}
                                                />
                                            )
                                        }

                                        {
                                            filterType === FILTER_TYPE.DATE_EXPIRATION && (
                                                <DatePicker
                                                    sx={{
                                                        '& .PrivateDatePickerRoot-picker': {
                                                            fontSize: '1rem',
                                                            overflow: 'hidden',
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            fontSize: '1rem',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            height: '46px',
                                                            fontSize: '0.9rem',
                                                        },
                                                    }}
                                                    label="Fecha Expiración"
                                                    value={finPromo}
                                                    onChange={async (newValue) => {
                                                        await handleSearchByDate(null, newValue)
                                                        setFinPromo(newValue)
                                                    }}
                                                />
                                            )
                                        }

                                        {
                                            filterType === FILTER_TYPE.DATE_BETWEEN && (
                                                <DemoContainer
                                                    sx={{
                                                        overflow: 'hidden',
                                                        paddingTop: 1,
                                                    }}
                                                    components={['DatePicker', 'DatePicker']}>
                                                    <>
                                                        <DatePicker
                                                            sx={{
                                                                '& .PrivateDatePickerRoot-picker': {
                                                                    fontSize: '1rem',
                                                                    overflow: 'hidden',
                                                                },
                                                                '& .MuiInputLabel-root': {
                                                                    fontSize: '1rem',
                                                                },
                                                                '& .MuiInputBase-root': {
                                                                    height: '42px',
                                                                    fontSize: '0.9rem',
                                                                    minWidth: 195,
                                                                    width: 195,
                                                                },
                                                            }}
                                                            label="Fecha Inicio"
                                                            value={inicioPromo}
                                                            onChange={async (newValue) => {
                                                                await handleSearchByDate(newValue, null)
                                                                setInicioPromo(newValue)
                                                            }}
                                                        />

                                                        <DatePicker
                                                            sx={{
                                                                '& .PrivateDatePickerRoot-picker': {
                                                                    fontSize: '1rem',
                                                                    overflow: 'hidden',
                                                                },
                                                                '& .MuiInputLabel-root': {
                                                                    fontSize: '1rem',
                                                                },
                                                                '& .MuiInputBase-root': {
                                                                    height: '42px',
                                                                    fontSize: '0.9rem',
                                                                    minWidth: 195,
                                                                    width: 195,
                                                                },
                                                            }}
                                                            label="Fecha Expiración"
                                                            value={finPromo}
                                                            onChange={async (newValue) => {
                                                                await handleSearchByDate(null, newValue)
                                                                setFinPromo(newValue)
                                                            }}
                                                        />
                                                    </>
                                                </DemoContainer>
                                            )
                                        }
                                    </LocalizationProvider>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    gap: 1,
                                    paddingTop: 1,
                                }}>
                                    <FormControl sx={{ minWidth: 140 }}>
                                        <InputLabel id="demo-simple-select-label">Búsqueda por campo</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={filterType}
                                            size="small"
                                            label="Búsqueda por fecha"
                                            onChange={handleChange}
                                        >
                                            {selectionFilterOptions.map((option) => (
                                                <MenuItem value={option.filterType} key={option.filterType}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </FormControl>

                                    <FormControl sx={{ minWidth: 140 }}>
                                        <InputLabel id="demo-simple-select-label">Único uso</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={onlyUse}
                                            size="small"
                                            label="Único que pues"
                                            onChange={async (event) => await handleChangeOnlyUse(event.target.value as FILTER_ONLY_USE)}
                                        >
                                            {optionsOnlyUse.map((option) => (
                                                <MenuItem value={option.filterType} key={option.filterType}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                        </Box>

                    </Collapse>

                </Box>

                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: {
                            xs: 'center',
                            md: 'flex-end'
                        },
                    }}
                >
                    <LoadingButton
                        variant="outlined"
                        startIcon={
                            <Icon sx={{ ml: 1 }}>description</Icon>
                        }
                        sx={{
                            height: '40px',
                            width: '160px',
                            borderRadius: '2px',
                            fontSize: '12px',
                            '& .MuiLoadingButton-loadingIndicator': {
                                color: 'white',
                            },
                        }}
                        onClick={handleOpenNewCampaña}
                    >
                        Nueva Campaña
                    </LoadingButton>
                </Box>
            </Box>

            <Box sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 2
            }}>
                {
                    loadingClientes ? (
                        <LinearProgress color="secondary" sx={{ width: '100%' }} />
                    ) : (
                        <CampañaTable
                            page={page}
                            rowsPerPage={rowsPerPage}
                            campañas={campañas}
                            busqueda={busqueda}
                            onlyUse={onlyUse}
                            inicioPromo={inicioPromo}
                            finPromo={finPromo}
                            handleSetPage={handleSetPage}
                            handleSetRowsPerPage={handleSetRowsPerPage}
                            handleGetCampañas={handleGetCampañas}
                            handleOpenEdit={handleOpenEdit}
                            handleOpenDialogDelete={handleOpenDialogDelete}
                        />
                    )
                }
            </Box>

            <CampañaForm
                open={openForm}
                campaña={selectedCampaña}
                handleClose={handleClose}
                handleCreateCampaña={handleCreateCampaña}
                handleUpdateCampaña={handleUpdateCampaña}
                addErrorMessage={addErrorMessage}
                handleCloseSnackbar={handleCloseSnackbar}
                loadingForm={loadingForm}
            />

            {
                selectedCampaña && (
                    <CampañaDelete
                        open={openDelete}
                        campaña={selectedCampaña}
                        handleClose={handleCloseDialogDelete}
                        handleDeleteCampaña={handleDeleteCampaña}
                        loadingDelete={loadingDeleteCampañas}
                    />
                )
            }
            <Snackbar
                open={alert !== undefined}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert severity={alert?.severity as AlertColor} onClose={handleCloseSnackbar}>
                    {alert?.message}
                </Alert>
            </Snackbar>

        </Dashboard>
    )
}
