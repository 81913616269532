import { LoadingButton } from "@mui/lab"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import { ICampaña } from "types"


interface Props {
    open: boolean
    campaña: ICampaña
    handleClose: () => void
    handleDeleteCampaña: () => Promise<void>
    loadingDelete: boolean
}

export const CampañaDelete = (props: Props) => {
    const {
        open,
        campaña,
        handleClose,
        handleDeleteCampaña,
        loadingDelete } = props
    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Eliminar Campaña</DialogTitle>
            <DialogContent>
                {campaña && (
                    <Typography variant="body1">
                        ¿Estás seguro de que deseas eliminar la campaña "{campaña.nombre}"?
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="small"
                    loading={loadingDelete}
                    disabled={loadingDelete}
                    sx={{
                        height: '40px', fontSize: '14px',
                        '& .MuiLoadingButton-loadingIndicator': {
                            color: 'white',
                        },
                    }}
                    onClick={async () => {
                        await handleDeleteCampaña()
                    }}
                >
                    ELIMINAR
                </ LoadingButton>
                <Button size="small" onClick={handleClose} sx={{ height: '40px', fontSize: '14px' }}>
                    CANCELAR
                </Button>
            </DialogActions>
        </Dialog>
    )
}
