import {
    Alert,
    Box,
    FormControl,
    Icon,
    InputLabel,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Snackbar,
    Typography
} from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { AcordionPrivacy, AcordionTerms } from 'components'
import { LOADING_OPTION } from 'enums'
import { getEmpresaListSelector, getUsuarioSelector } from 'features'
import Lottie from 'lottie-react'
import { Dashboard } from 'pages'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { getAPTCService, uploadJsonAPTCService } from 'services'
import { IEmpresa, IShowNotification, IStructureAP, IStructureTC, ITypeAPTC } from 'types'
import uploadingAnimation from "../../../assets/lotties/UploadingFile.json"
import { useLoading } from '../../../hooks/useLoading'
import { LoadingButton } from '@mui/lab'
import saveAs from 'file-saver'

export const ManageAPTC = () => {

    const usuario = useAppSelector(getUsuarioSelector)
    const empresaList = useAppSelector(getEmpresaListSelector)
    const [empresa, setEmpresa] = React.useState<number>(0)
    const { loading, handleChangeLoading, handleCloseLoad } = useLoading<LOADING_OPTION>()
    const uploadingJson = loading.includes(LOADING_OPTION.UPLOAD_JSON)
    const getAPTCloading = loading.includes(LOADING_OPTION.GET_APTC_JSON)
    const donwloading = loading.includes(LOADING_OPTION.DOWNLOAD_JSON)
    const [infoTerms, setTerms] = React.useState<IStructureTC | null>(null)
    const [infoAP, setAP] = React.useState<IStructureAP | null>(null)
    const [selectedType, setSelectedType] = React.useState<ITypeAPTC>('TC')
    const [alert, setAlert] = React.useState<IShowNotification>({ open: false, title: '', message: "", severity: "success" })

    const loadingJson = React.useMemo(() => {
        return uploadingJson || getAPTCloading
    }, [getAPTCloading, uploadingJson])


    const showNotification = (alert: IShowNotification) => {
        setAlert(alert)
    }

    const handleChangeEmpresa = (empresas: IEmpresa[]) => {
        if (empresas.length > 0) {
            setEmpresa(empresas[0].id)
        } else {
            setEmpresa(0)
        }
    }

    const validateExtencionDocument = (filename: string) => {
        const validExtensions = ['json']
        const extension = filename.split('.').pop()?.toLowerCase()
        return validExtensions.includes(extension || '')
    }

    const filteredEmpresas = React.useMemo(() => {
        const validEmpresas: IEmpresa[] = []
        if (usuario !== undefined) {
            if (usuario?.permisoSuperdeporte && empresaList[0]) {
                validEmpresas.push(empresaList[0])
            }
            if (usuario?.permisoMedeportAdidas && empresaList[1]) {
                validEmpresas.push(empresaList[1])
            }
            if (usuario?.permisoMedeportReebok && empresaList[2]) {
                validEmpresas.push(empresaList[2])
            }
            if (usuario?.permisoEquinox && empresaList[3]) {
                validEmpresas.push(empresaList[3])
            }
        }
        handleChangeEmpresa(validEmpresas)
        return validEmpresas
    }, [empresaList, usuario])


    const getValidateTypeJson = async (file: File) => {
        const fileContent = await file.text()
        const jsonData = JSON.parse(fileContent)
        let message
        if (jsonData === undefined) {
            message = "Archivo vacío, o no coicide con el formato de TC y AP"
        }

        if (selectedType === 'AP' && Array.isArray(jsonData.terminos)) {
            message = "Estás tratando de subir un archivo de términos en Aviso de Privacidad"
        } else if (selectedType === 'TC' && Array.isArray(jsonData.privacidad)) {
            message = "Estás tratando de subir un archivo de privacidad en Términos y condiciones"
        }

        if (message) {
            showNotification({
                open: true,
                severity: 'error',
                title: 'Estructura del archivo',
                message
            })
            return false
        }
        return true
    }
    const handleDownloadJson = async (jsonData: IStructureTC | IStructureAP) => {
        try {
            handleChangeLoading(LOADING_OPTION.DOWNLOAD_JSON)
            if (jsonData) {
                const jsonString = JSON.stringify(jsonData, null, 2)
                const blob = new Blob([jsonString], { type: 'application/json' })
                const name = filteredEmpresas[empresa - 1].nombre
                saveAs(blob, `${name}-${selectedType}.json`)
            }

        } catch (error) {
            console.error('Error al momento de descargar.')
            showNotification({
                open: true,
                severity: 'error',
                title: 'Error',
                message: 'No se pudo descargar el archivo json'
            })
        } finally {
            handleCloseLoad(LOADING_OPTION.DOWNLOAD_JSON)
        }

    }


    const handleUploadFile = async (file: File) => {
        try {
            handleChangeLoading(LOADING_OPTION.UPLOAD_JSON)
            if (file) {
                const response = await uploadJsonAPTCService({ file, empresaId: empresa, type: selectedType })
                if (response.status === 200) {
                    selectedType === 'AP' ? setAP(response.data) : setTerms(response.data)
                    selectedType === 'AP' ? setTerms(null) : setAP(null)
                    showNotification({
                        open: true,
                        severity: 'success',
                        title: 'Archivo json',
                        message: 'Archivo json cargado correctamente'
                    })
                }
            }
        } catch (error: any) {
            console.log("Error", error)
            showNotification({
                open: true,
                severity: 'error',
                title: 'Error al subir el archivo',
                message: error.message ?? 'Error al subir el archivo json'
            })
        } finally {
            handleCloseLoad(LOADING_OPTION.UPLOAD_JSON)
        }
    }

    const onDrop = async (acceptedFiles: File[]) => {
        try {
            if (acceptedFiles.length > 0) {
                const file = acceptedFiles[0]
                if (!validateExtencionDocument(file.name)) {
                    showNotification({
                        open: true,
                        severity: 'error',
                        title: 'Formato del archivo',
                        message: 'La extención del archivo no es json'
                    })
                    return
                }
                //? validador, antes de subir
                const isValidTypeJsonUpload = await getValidateTypeJson(file)
                if (isValidTypeJsonUpload) {
                    console.log("ENTRA SUBIR")
                    await handleUploadFile(file)
                }
            }
        } catch (error: any) {
            console.log("Error", error)
        }
    }

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: { 'text/csv': ['.json'] },
        multiple: false
    })

    const getAPTCByEmpresaAndType = async () => {
        try {
            handleChangeLoading(LOADING_OPTION.GET_APTC_JSON)
            const response = await getAPTCService(empresa, selectedType)
            if (response) {
                selectedType === 'AP' ? setAP(response) : setTerms(response)
                selectedType === 'AP' ? setTerms(null) : setAP(null)
            }
        } catch (error) {
            console.log("Error", error)
        } finally {
            handleCloseLoad(LOADING_OPTION.GET_APTC_JSON)
        }
    }

    React.useEffect(() => {
        if (empresa && selectedType) {
            getAPTCByEmpresaAndType()
        }
    }, [empresa, selectedType])

    return (
        <Dashboard title="Aviso de Privacidad - Términos y Condiciones">
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: '80vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                    pl: 3
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    width: '100%',
                    height: '100%',
                }}>
                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                        width: '100%',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: 'center'

                    }}>
                        <FormControl size="small">
                            <InputLabel sx={{ color: '#C4C4C4' }}>Empresa</InputLabel>
                            <Select
                                value={empresa !== undefined ? empresa.toString() : ''}
                                onChange={async (event: SelectChangeEvent) => {
                                    const empresaSelected = Number.parseInt(event.target.value)
                                    setEmpresa(empresaSelected)
                                    setAP(null)
                                    setTerms(null)
                                }}
                                sx={{ width: '240px' }}
                                label="Empresa"
                                style={{ borderRadius: '0px', fontSize: '18px' }}
                            >
                                {
                                    filteredEmpresas.map((empresa) => (
                                        empresa ? (
                                            <MenuItem key={empresa.id} value={empresa.id}>
                                                {empresa.nombre}
                                            </MenuItem>
                                        ) : null
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl size="small">
                            <InputLabel sx={{ color: '#C4C4C4' }}>Tipo</InputLabel>
                            <Select
                                value={selectedType}
                                onChange={(event: SelectChangeEvent) => {
                                    const value = event.target.value as ITypeAPTC
                                    setSelectedType(value)
                                }}
                                sx={{ width: '260px' }}
                                label="Tipo"
                                style={{ borderRadius: '0px', fontSize: '18px' }}
                            >
                                <MenuItem value="TC" sx={{ fontSize: '18px' }}>Términos y Condiciones</MenuItem>
                                <MenuItem value="AP" sx={{ fontSize: '18px' }}>Aviso de privacidad</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{
                        py: 2.5,
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        width: '100%',
                        pt: 5
                    }}>

                        <Paper
                            elevation={3}
                            sx={{
                                padding: 2,
                                width: '50%',
                                height: '200px',
                                maxWidth: '50%',
                                textAlign: 'center',
                                cursor: 'pointer',
                                border: '2px dashed #ccc',
                                display: 'flex',
                                justifyContent: 'center',
                                borderRadius: 4,
                                my: 0.5,
                                pointerEvents: uploadingJson ? 'none' : 'auto',
                            }}
                            {...getRootProps()}
                        >
                            <input {...getInputProps()} disabled={uploadingJson} />
                            {!uploadingJson && (
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Icon sx={{ fontSize: 100, color: '#aaa' }}>insert_drive_file</Icon>
                                    <Typography variant="h6" color="textSecondary">
                                        Arrastra y suelta un archivo json aquí, o haz clic para seleccionar uno
                                    </Typography>
                                </Box>
                            )}

                            {uploadingJson && (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%'
                                }}>
                                    <Lottie
                                        animationData={uploadingAnimation}
                                        loop={true}
                                        style={{ width: 370, height: 280 }}
                                    />
                                </Box>
                            )}
                        </Paper>
                        {
                            selectedType === 'TC' ? (
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    mx: 2,
                                    mb: 4,
                                    width: 500,
                                }}>
                                    {
                                        infoTerms === undefined || infoTerms === null ? (
                                            <Box sx={{ width: '100%', textAlign: 'center' }}>
                                                {loadingJson ? (
                                                    <>
                                                        <LinearProgress color="secondary" sx={{ width: '100%' }} />
                                                        <Typography variant="h5" color="initial">Cargando...</Typography>
                                                    </>
                                                ) : (
                                                    <Typography variant="h5" color="initial">No tiene registro de Términos</Typography>
                                                )}
                                            </Box>
                                        ) : (
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}>

                                                <LoadingButton
                                                    disabled={donwloading}
                                                    loading={donwloading}
                                                    endIcon={<Icon>download</Icon>}
                                                    sx={{
                                                        borderRadius: 4,
                                                        width: '70%',
                                                        height: 45,
                                                        fontSize: 20,
                                                        mb: 2
                                                    }}
                                                    onClick={async () => {
                                                        await handleDownloadJson(infoTerms)
                                                    }}
                                                >Descargar json</LoadingButton>
                                                <AcordionTerms termsInfo={infoTerms} />

                                            </Box>
                                        )

                                    }
                                </Box>
                            ) : (
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    mx: 2,
                                    mb: 4,
                                    width: 500,
                                }}>
                                    {
                                        infoAP === undefined || infoAP === null ? (
                                            <Box sx={{ width: '100%', textAlign: 'center' }}>
                                                {loadingJson ? (
                                                    <>
                                                        <LinearProgress color="secondary" sx={{ width: '100%' }} />
                                                        <Typography variant="h5" color="initial">Cargando...</Typography>
                                                    </>
                                                ) : (
                                                    <Typography variant="h5" color="initial">No tiene registro de privacidad</Typography>
                                                )}
                                            </Box>
                                        ) : (
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}>
                                                <LoadingButton
                                                    disabled={donwloading}
                                                    loading={donwloading}
                                                    endIcon={<Icon>download</Icon>}
                                                    sx={{
                                                        borderRadius: 4,
                                                        width: '70%',
                                                        height: 45,
                                                        fontSize: 20,
                                                        mb: 2
                                                    }}
                                                    onClick={async () => {
                                                        await handleDownloadJson(infoAP)
                                                    }}
                                                >Descargar json</LoadingButton>
                                            
                                                <AcordionPrivacy privacyInfo={infoAP} />

                                            </Box>
                                        )
                                    }
                                </Box>
                            )
                        }

                    </Box>
                    <Box>
                    </Box>
                </Box>
                <Snackbar open={alert.open}
                    autoHideDuration={5000}
                    onClose={() => setAlert({ ...alert, open: false })}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                    <Alert variant="filled"
                        onClose={() => setAlert({ ...alert, open: false })}
                        severity={alert.severity}>
                        {alert.message}
                    </Alert>
                </Snackbar>
            </Box >
        </Dashboard >
    )
}
