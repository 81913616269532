import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { ICatalogo, ICatalogoState, IEmpresa, IGenero, ITipoCliente, ITipoDocumento } from 'types'

const initialState = {
    tipoDocumentoList: [] as ITipoDocumento[],
    generoList: [] as IGenero[],
    empresaList: [] as IEmpresa[],
    tipoClienteList: [] as ITipoCliente[],
    categoriaList: [] as ICatalogo[],
    equipoList: [] as ICatalogo[],

} as ICatalogoState

const CatalogoSlice = createSlice({
    name: 'catalogo',
    initialState,
    reducers: {
        setTipoDocumentoList: (state, action: PayloadAction<ITipoDocumento[]>) => {
            const tipoDocumentoList = action.payload
            return { ...state, tipoDocumentoList }
        },
        setGeneroList: (state, action: PayloadAction<IGenero[]>) => {
            const generoList = action.payload
            return { ...state, generoList }
        },
        setEmpresaList: (state, action: PayloadAction<IEmpresa[]>) => {
            const empresaList = action.payload
            return { ...state, empresaList }
        },
        setTipoClienteList: (state, action: PayloadAction<ITipoCliente[]>) => {
            const tipoClienteList = action.payload
            return { ...state, tipoClienteList }
        },
        setCategoriaList: (state, action: PayloadAction<ICatalogo[]>) => {
            const categoriaList = action.payload
            return { ...state, categoriaList }
        },
        setEquipoList: (state, action: PayloadAction<ICatalogo[]>) => {
            const equipoList = action.payload
            return { ...state, equipoList }
        }
    },
})

export const catalogoReducer = CatalogoSlice.reducer

export const {
    setTipoDocumentoList,
    setGeneroList,
    setEmpresaList,
    setTipoClienteList,
    setCategoriaList,
    setEquipoList
} = CatalogoSlice.actions

export const getTipoDocumentoListSelector = (state: RootState) => state.catalogo.tipoDocumentoList

export const getGeneroListSelector = (state: RootState) => state.catalogo.generoList

export const getEmpresaListSelector = (state: RootState): IEmpresa[] => state.catalogo.empresaList

export const getTipoClienteListSelector = (state: RootState) => state.catalogo.tipoClienteList

export const getCategoriaSelector = (state: RootState) => state.catalogo.categoriaList

export const getEquipoSelector = (state: RootState) => state.catalogo.equipoList