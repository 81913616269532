export enum LOADING_OPTION {
    LOADING_CLIENTS = 'loadingClients',
    LOADING_CAMPAÑAS = 'loadingCampañas',
    DELETE_CAMPAÑA = 'deleteCampaña',
    LOADING_FORM_CAMPAÑA = 'loadingFormCampaña',
    SEARCH_CLIENTS = 'searchClients',
    SEARCH_CAMPAÑAS = 'searchCampañas',
    CLEAR_SEARCH_CLIENTS = 'clearSearchClients',
    CLEAR_SEARCH_CAMPAÑAS = 'clearSearchCampañas',
    GENERATE_REPORT = 'loadingGetReport',
    UPLOAD_CSV = 'uploadCSV',
    UPLOAD_JSON = 'uploadJson',
    GET_APTC_JSON = 'getAPTCJson',
    DOWNLOAD_JSON = 'downloadJson',
}
