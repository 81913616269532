import { ICliente, IEmpresa, IPublicFormError } from 'types'
import dayjs from 'dayjs'

const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

export const validateClientPublicForm = (data: ICliente, empresaList: IEmpresa[], empresaId: number) => {
    const actualAlerts: IPublicFormError[] = []
    if (data.nombres === undefined || data.nombres.trim().length <= 0) {
        actualAlerts.push({ key: 'nombres', message: 'Debe ingresar sus nombres.', typeError: 'error' })
    }

    if (data.apellidos === undefined || data.apellidos.trim().length <= 0) {
        actualAlerts.push({ key: 'apellidos', message: 'Debe ingresar sus apellidos.', typeError: 'error' })
    }

    if (data.tipoDocumento === undefined) {
        actualAlerts.push({ key: 'tipoDocumento', message: 'Debe seleccionar un tipo de documento.', typeError: 'error' })
    }

    if (data.numDocumento === undefined || data.numDocumento.trim().length <= 0) {
        actualAlerts.push({ key: 'numDocumento', message: 'Debe ingresar un número de documento.', typeError: 'error' })
    }

    if (
        data.tipoDocumento !== undefined &&
        (data.tipoDocumento === '04' || data.tipoDocumento === '05') &&
        data.numDocumento !== undefined &&
        data.numDocumento.trim().length > 0
    ) {
        if (data.tipoDocumento === '04' && (data.numDocumento.match(/^[0-9]+$/) === null || data.numDocumento.trim().length !== 10)) {
            actualAlerts.push({ key: 'numDocumento', message: 'Debe ingresar una cédula válida.', typeError: 'error' })
        }
        if (
            data.tipoDocumento === '05' &&
            (data.numDocumento.match(/^[0-9]+$/) === null || data.numDocumento.trim().length !== 13 || !data.numDocumento.endsWith('001'))
        ) {
            actualAlerts.push({ key: 'numDocumento', message: 'Debe ingresar un RUC válido.', typeError: 'error' })
        }
    }

    if (data.genero === undefined) {
        actualAlerts.push({ key: 'genero', message: 'Debe seleccionar un género.', typeError: 'error' })
    }

    if (data.email === undefined || data.email.trim().length <= 0) {
        actualAlerts.push({ key: 'email', message: 'Debe ingresar un email.', typeError: 'error' })
    }

    if (data.email !== undefined && data.email.trim().length > 0) {
        if (!expression.test(data.email)) {
            actualAlerts.push({ key: 'email', message: 'Debe ingresar un email válido.', typeError: 'error' })
        }
    }

    if (data.fechaNacimiento === undefined) {
        actualAlerts.push({ key: 'fechaNacimiento', message: 'Debe selecionar su fecha de nacimiento.', typeError: 'error' })
    }

    if (data.fechaNacimiento !== undefined && data.fechaNacimiento.trim().length > 0) {
        if (data.fechaNacimiento.toString() === 'Invalid Date') {
               actualAlerts.push({ key: 'fechaNacimiento', message: `Error con la fecha`, typeError: 'error' })
        } else {
            try {
                const nacimientoDayjs = dayjs(data.fechaNacimiento)
                const age = dayjs().diff(nacimientoDayjs, 'year')
                const correo = empresaList.find((empresa) => empresa.id === empresaId)!.correo
                if (age < 18) {
                    actualAlerts.push({ key: 'fechaNacimiento', message: `Si eres menor de edad comunícate con el mail ${correo}`, typeError: 'error' })
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

 
    if (data.celular === undefined || data.celular.trim().length === 0) {
        actualAlerts.push({ key: 'celular', message: 'Debe ingresar un número celular.', typeError: 'error' })
    }
    
    if (data.celular !== undefined && data.celular.trim().length !== 0) {
        if (data.celular.trim().length < 9) {
            actualAlerts.push({ key: 'celular', message: 'Número de celular incorrecto.', typeError: 'error' })
        } else if (data.celular.trim().length === 9) {
            if (!/^9/.test(data.celular)) {
                actualAlerts.push({ key: 'celular', message: 'El número debe iniciar con 9', typeError: 'error' })
            }
        } else if (data.celular.trim().length === 10) {
            if (!/^09/.test(data.celular)) {
                actualAlerts.push({ key: 'celular', message: 'El número debe iniciar con 09', typeError: 'error' })
            }
        }
    }
    if (data.avisoPrivacidad === undefined || !data.avisoPrivacidad) {
        actualAlerts.push({ key: 'avisoPrivacidad', message: 'Debe aceptar el aviso de privacidad.', typeError: 'error' })
    }

    if (data.terminos === undefined || !data.terminos) {
        actualAlerts.push({ key: 'terminos', message: 'Debe aceptar los términos y condiciones.', typeError: 'error' })
    }

    return actualAlerts
}
