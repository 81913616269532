import { LOCALSTORAGE_KEYS } from 'const'
import React from 'react'
import { getUsuarioByTokenService, loginUsuarioService, logoutUsuario } from 'services'
import { UsuarioLoginRequest, UsuarioResponse } from 'types'
import { useLocalStorage } from './useLocalStorage'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { clearUsuario, getUsuarioSelector, setUsuario } from 'features'
import { ROUTES } from 'routes'
import { useNavigate } from 'react-router-dom'

interface Hook {
    isAuthenticated: () => boolean
    handleLogin: (credentials: UsuarioLoginRequest) => void
    handleLogout: () => void
    findUserByToken: () => any | undefined
    usuario: UsuarioResponse | undefined
    token: string | null
    loading: boolean
}

export const useAuth = (): Hook => {
    const { storeItem, deleteItem, getItem } = useLocalStorage()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [loading, setLoading] = React.useState(false)
    const usuario = useAppSelector(getUsuarioSelector)
    const token = getItem(LOCALSTORAGE_KEYS.MARATHON)

    const handleLogin = async (credentials: UsuarioLoginRequest) => {
        try {
            setLoading(true)
            const user = await loginUsuarioService(credentials)
            storeItem(LOCALSTORAGE_KEYS.MARATHON, (user as UsuarioResponse).token)
            dispatch(setUsuario(user))
            navigate(ROUTES.root + ROUTES.private + ROUTES.root + ROUTES.clientes)
        } catch (error: any) {
            throw new Error(error.response?.data?.message ?? error.message)
        } finally {
            setLoading(false)
        }
    }

    const isAuthenticated = React.useCallback(() => (token ? true : false), [token])

    const handleLogout = React.useCallback(async () => {
        try {
            setLoading(true)
            if (usuario) {
                await logoutUsuario(usuario.usuarioId)
                deleteItem(LOCALSTORAGE_KEYS.MARATHON)
                dispatch(clearUsuario())
            }         
            navigate(ROUTES.root + ROUTES.login)
        } catch (error: any) {
            throw new Error(error.message)
        } finally {
            setLoading(false)
        }
    }, [usuario])

    const findUserByToken = async () => {
        if (token) {
            try {
                setLoading(true)
                const usuario = await getUsuarioByTokenService(token)
                if (usuario) {
                    dispatch(setUsuario(usuario))
                    storeItem(LOCALSTORAGE_KEYS.MARATHON, (usuario as UsuarioResponse).token)
                }
            } catch (error: any) {
                dispatch(setUsuario(null as any | undefined))
                deleteItem(LOCALSTORAGE_KEYS.MARATHON)
            } finally {
                setLoading(false)
            }
        } else {
            navigate(ROUTES.root + ROUTES.login)
        }
    }

    return {
        isAuthenticated,
        handleLogin,
        handleLogout,
        findUserByToken,
        usuario,
        token,
        loading,
    }
}
