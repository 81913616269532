import axios, { AxiosError } from 'axios'
import { LOCALSTORAGE_KEYS, PATHS } from 'const'
import { format } from 'date-fns'
import { CampañaPaginationResponse } from 'types'
import { ICampañaRequest } from 'types/request/campaña'

type IHeaders = {
    Authorization: string
    Token: string
}
const getItem = (key: string): any | undefined => {
    const value = localStorage.getItem(key)
    try {
        if (value) {
            const json = JSON.parse(value)
            return json
        }
    } catch (error) {
        return undefined
    }
}
const getHeaders = (): IHeaders => {
    const token = getItem(LOCALSTORAGE_KEYS.MARATHON) ?? ''
    return {
        Authorization: `Bearer ${process.env.REACT_APP_SECURITY_API_KEY}`,
        Token: token,
    }
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
})

const setAuthHeaders = (Authorization: string, Token: string) => {
    instance.defaults.headers.common['Authorization'] = Authorization
    instance.defaults.headers.common['Token'] = Token
}

export const insertCampañaService = async (campaña: ICampañaRequest) => {
    try {
        const response = await instance.post(PATHS.CAMPAÑA_PATH, campaña)

        return response
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}

export const getCampañasPaginateService = async (
    page: number,
    limit: number,
    onlyUse: string,
    busqueda: string,
    inicioPromo: Date | null,
    finPromo: Date | null
) => {
    try {
        const { Authorization, Token } = getHeaders()
        setAuthHeaders(Authorization, Token)
        const response = await instance.get(PATHS.CAMPAÑA_PATH, {
            params: {
                page,
                limit,
                busqueda,
                onlyUse,
                inicioPromo: inicioPromo !== null ? format(inicioPromo, 'yyyy-MM-dd') : undefined,
                finPromo: finPromo !== null ? format(finPromo, 'yyyy-MM-dd') : undefined,
            },
        })
        return response.data as CampañaPaginationResponse
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}

export const updateCampañaService = async (campaña: ICampañaRequest) => {
    try {
        const { Authorization, Token } = getHeaders()
        setAuthHeaders(Authorization, Token)
        const response = await instance.put(PATHS.CAMPAÑA_PATH, campaña)
        return response
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}

export const deleteCampañaService = async (campañaId: number) => {
    try {
        const { Authorization, Token } = getHeaders()
        setAuthHeaders(Authorization, Token)
        const response = await instance.delete(`${PATHS.CAMPAÑA_PATH}/${campañaId}`)
        return response
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}
