import AddCircleIcon from '@mui/icons-material/AddCircle'
import { LoadingButton } from '@mui/lab'
import {
    Alert,
    AlertColor,
    Box,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Snackbar,
    TextField,
    Tooltip,
} from '@mui/material'
import { DIALOGS_CLIENT } from 'const'
import React, { Fragment } from 'react'
import { sendEmailClienteService } from 'services'
import { IClienteResponse, IAlert } from 'types'
import { fieldValidationEmail } from 'utils'
interface Props {
    open: boolean
    cliente: IClienteResponse | undefined
    handleClose: (dialog: string) => void
}

export const SendEmailDialog = (props: Props) => {
    const { open, cliente, handleClose } = props
    const [alert, setAlert] = React.useState<IAlert>()
    const [email, setEmail] = React.useState<string>()
    const [emails, setEmails] = React.useState<string[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)
    const actualAlerts: IAlert[] = []

    React.useEffect(() => {
        if (cliente?.email) {
            setEmails([cliente.email])
        }
    }, [cliente])

    const addOtherEmail = () => {
        if (email === undefined || email.trim().length <= 0) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe ingresar ingresar un correo' })
        }
        if (!fieldValidationEmail(email as string)) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'NO es un correo válido' })
        }
        if (emails.includes(email as string)) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'El correo ya se encuentra agregado' })
        }
        if (actualAlerts.length > 0) {
            setAlert(actualAlerts[0])
        } else {
            setAlert(undefined)
            setEmails([...emails, email as string])
            setEmail('')
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (emails === undefined || emails.length <= 0) {
            actualAlerts.push({ severity: 'error', title: 'Error', message: 'Debe al menos haber un correo' })
        }
        if (actualAlerts.length > 0) {
            setAlert(actualAlerts[0])
            return
        } else {
            try {
                if (cliente) {
                    setLoading(true)
                    await handleSendEmail(cliente)
                }
                setEmail('')
            } catch (error) {
                console.log(error)
            } finally {
                setAlert({ severity: 'success', title: '', message: 'Se ha enviado el mensaje a los correos.' })
                handleClose(DIALOGS_CLIENT.SEND_EMAIL)
                setEmails([])
                setLoading(false)
            }
        }
    }

    const handleCloseSnackbar = () => {
        setAlert(undefined)
    }

    const handleDelete = (email: string) => {
        const newEmails = emails.filter((e) => e !== email)
        setEmails(newEmails)
    }

    const handleSendEmail = async (cliente: IClienteResponse) => {
        try {
            await sendEmailClienteService(cliente.certificado, cliente.empresa, emails)
        } catch (error) {
            console.log('ERROR AL ENVIAR CORREO')
        }
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={() => {
                    handleClose(DIALOGS_CLIENT.SEND_EMAIL)
                }}
                fullWidth
                maxWidth="xs"
            >
                <form onSubmit={handleSubmit}>
                    <DialogTitle>Correos para enviar codigo QR</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Puedes agregar mas correos a quienes quieres enviar el código QR.</DialogContentText>
                        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', pt: 2 }}>
                            {emails &&
                                emails.map((email, index) => {
                                    return <Chip key={index} label={email} onDelete={() => handleDelete(email)} />
                                })}
                        </Box>
                        <Grid container gap={2} sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Grid item xs={10}>
                                <TextField
                                    id="email"
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    size="small"
                                    value={email}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setEmail(event.target.value)
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip title="Agregar Correo" placement="top">
                                                <IconButton onClick={addOtherEmail} sx={{ fontSize: 27 }}>
                                                    <AddCircleIcon />
                                                </IconButton>
                                            </Tooltip>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            size="small"
                            sx={{
                                height: '40px',
                                fontSize: '14px',
                                '& .MuiLoadingButton-loadingIndicator': {
                                    color: 'white',
                                }, }}
                            type="submit"
                            loading={loading}
                            loadingIndicator={<CircularProgress size={20} sx={{ color: '#fff' }} />}
                        >
                            {'Enviar QR'}
                        </LoadingButton>

                        <Button
                            size="small"
                            onClick={() => {
                                handleClose(DIALOGS_CLIENT.SEND_EMAIL)
                            }}
                            sx={{ height: '40px', fontSize: '14px' }}
                        >
                            CANCELAR
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Snackbar open={alert !== undefined} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                <Alert severity={alert?.severity as AlertColor} onClose={handleCloseSnackbar}>
                    {alert?.message}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}
