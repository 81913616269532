import { Accordion, AccordionDetails, AccordionSummary, Icon, Typography } from '@mui/material'
import { Fragment } from 'react'
import { IStructureAP } from 'types'

type Props = {
    privacyInfo: IStructureAP
}

export const AcordionPrivacy = (props: Props) => {
    const {  privacyInfo } = props
    return (
        <Fragment>
            { privacyInfo.privacidad.map((section, sectionIndex) =>
                section.map((info, index) => (
                    <Accordion key={`${sectionIndex}-${index}`}>
                        <AccordionSummary
                            expandIcon={<Icon>expand_more</Icon>}
                            aria-controls={`panel${sectionIndex}-${index}-content`}
                            id={`panel${sectionIndex}-${index}-header`}
                            style={{ marginBottom: '0' }}
                        >
                            <Typography dangerouslySetInnerHTML={{ __html: info.title }} />
                        </AccordionSummary>
                        <AccordionDetails style={{ textAlign: 'justify', paddingTop: '0px' }}>
                            {info.paragraphs.map((paragraph, pIndex) => (
                                <p
                                    key={pIndex}
                                    dangerouslySetInnerHTML={{ __html: paragraph }}
                                    style={{
                                        marginTop: '0px',
                                        overflowWrap: 'break-word',
                                        wordWrap: 'break-word',
                                        wordBreak: 'break-word',
                                    }}
                                />
                            ))}
                        </AccordionDetails>
                    </Accordion>
                ))
            )}
        </Fragment>
    )
}

