import {
    Alert,
    Box,
    Divider,
    FormControl,
    Icon,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Snackbar,
    Typography
} from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { LOADING_OPTION } from 'enums'
import { getEmpresaListSelector, getUsuarioSelector } from 'features'
import Lottie from 'lottie-react'
import { Dashboard } from 'pages'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { uploadPlantillaCSVService } from 'services'
import { IEmpresa, IShowNotification, ISummaryClientCSV } from 'types'
import uploadingAnimation from "../../../assets/lotties/UploadingFile.json"
import { useLoading } from '../../../hooks/useLoading'

export const CargaMasiva = () => {

    const usuario = useAppSelector(getUsuarioSelector)
    const empresaList = useAppSelector(getEmpresaListSelector)
    const [empresa, setEmpresa] = React.useState<number>(0)
    const { loading, handleChangeLoading, handleCloseLoad } = useLoading<LOADING_OPTION>()
    const uploadingCSV = loading.includes(LOADING_OPTION.UPLOAD_CSV)
    const [summaryClientCSV, setSummaryClientCSV] = React.useState<ISummaryClientCSV>()
    const [alert, setAlert] = React.useState<IShowNotification>({ open: false, title: '', message: "", severity: "success" })

    const showNotification = (alert: IShowNotification) => {
        setAlert(alert)
    }

    const handleChangeEmpresa = (empresas: IEmpresa[]) => {
        if (empresas.length > 0) {
            setEmpresa(empresas[0].id)
        } else {
            setEmpresa(0)
        }
    }

    const validateExtencionDocument = (filename: string) => {
        const validExtensions = ['csv']
        const extension = filename.split('.').pop()?.toLowerCase()
        return validExtensions.includes(extension || '')
    }

    const filteredEmpresas = React.useMemo(() => {
        const validEmpresas: IEmpresa[] = []
        if (usuario !== undefined) {
            if (usuario?.permisoSuperdeporte && empresaList[0]) {
                validEmpresas.push(empresaList[0])
            }
            if (usuario?.permisoMedeportAdidas && empresaList[1]) {
                validEmpresas.push(empresaList[1])
            }
            if (usuario?.permisoMedeportReebok && empresaList[2]) {
                validEmpresas.push(empresaList[2])
            }
            if (usuario?.permisoEquinox && empresaList[3]) {
                validEmpresas.push(empresaList[3])
            }
        }
        handleChangeEmpresa(validEmpresas)
        return validEmpresas
    }, [empresaList, usuario])

    const handleUploadFile = async (file: File) => {
        try {
            handleChangeLoading(LOADING_OPTION.UPLOAD_CSV)
            if (file) {
                const response = await uploadPlantillaCSVService(file, empresa, usuario!.usuarioId)
                setSummaryClientCSV(response.data)
                if (response.status === 200) {
                    showNotification({
                        open: true,
                        severity: 'success',
                        title: 'Archivo CSV',
                        message: 'Archivo cargado correctamente'
                    })
                }

            }
        } catch (error: any) {

            showNotification({
                open: true,
                severity: 'error',
                title: 'Error al subir documento',
                message: error.message ?? 'Error al subir el archivo'
            })
        } finally {
            handleCloseLoad(LOADING_OPTION.UPLOAD_CSV)
        }
    }

    const onDrop = async (acceptedFiles: File[]) => {
        try {
            if (acceptedFiles.length > 0) {
                const file = acceptedFiles[0]
                if (!validateExtencionDocument(file.name)) {
                    showNotification({
                        open: true,
                        severity: 'error',
                        title: 'Formato del archivo',
                        message: 'La extención del archivo no es csv'
                    })
                    return
                }


                await handleUploadFile(file)

            }
        } catch (error: any) {
            console.log("Error", error)
        }
    }


    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: { 'text/csv': ['.csv'] },
        multiple: false
    })

    return (
        <Dashboard title="Subida de clientes">

            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: '80vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    width: '100%',
                    height: '100%',
                }}>
                    <Box sx={{
                        displa: 'flex',
                        justifyContent: 'flex-start',
                    }}>
                        <FormControl size="small">
                            <InputLabel sx={{ color: '#C4C4C4' }}>Empresa</InputLabel>
                            <Select
                                value={empresa !== undefined ? empresa.toString() : ''}
                                onChange={async (event: SelectChangeEvent) => {
                                    const empresaSelected = Number.parseInt(event.target.value)
                                    setEmpresa(empresaSelected)
                                }}
                                sx={{ width: '240px' }}
                                label="Empresa"
                                style={{ borderRadius: '0px' }}
                            >
                                {
                                    filteredEmpresas.map((empresa) => (
                                        empresa ? (
                                            <MenuItem key={empresa.id} value={empresa.id}>
                                                {empresa.nombre}
                                            </MenuItem>
                                        ) : null
                                    ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{
                        py: 2.5,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        width: '100%',
                    }}>

                        <Paper
                            elevation={3}
                            sx={{
                                padding: 2,
                                width: '50%',
                                height: '200px',
                                maxWidth: '50%',
                                textAlign: 'center',
                                cursor: 'pointer',
                                border: '2px dashed #ccc',
                                display: 'flex',
                                justifyContent: 'center',
                                borderRadius: 4,
                                my: 0.5,
                                pointerEvents: uploadingCSV ? 'none' : 'auto',
                            }}
                            {...getRootProps()}
                        >
                            <input {...getInputProps()} disabled={uploadingCSV} />
                            {!uploadingCSV && (
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Icon sx={{ fontSize: 100, color: '#aaa' }}>cloud_upload</Icon>
                                    <Typography variant="h6" color="textSecondary">
                                        Arrastra y suelta un archivo CSV aquí, o haz clic para seleccionar uno
                                    </Typography>
                                </Box>
                            )}

                            {uploadingCSV && (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%'
                                }}>
                                    <Lottie
                                        animationData={uploadingAnimation}
                                        loop={true}
                                        style={{ width: 370, height: 280 }}
                                    />
                                </Box>
                            )}

                        </Paper>

                        {summaryClientCSV && (
                            <React.Fragment>
                                <Box
                                    sx={{
                                        textAlign: "left",
                                        marginTop: "20px",
                                        padding: "2px 30px",
                                        backgroundColor: "#6b6b6b",
                                        borderRadius: "5px",
                                        width: "50%",
                                    }}
                                >
                                    <Typography variant="body1" style={{ color: "white" }}>
                                        Respuestas del servicio:
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        width: "50%",
                                        marginTop: "20px",
                                        border: "2px dashed #949494",
                                        borderRadius: "10px",
                                        textAlign: "center",

                                    }}
                                >
                                    <List
                                        sx={{
                                            width: "100%",
                                            height: "230px",
                                            maxHeight: "230px",
                                            overflowX: 'hidden',
                                            overflowY: "auto",
                                        }}
                                    >
                                        <ListItem sx={{
                                            display: 'flex',
                                            width: '100%',
                                            justifyContent: 'space-between',
                                            textAlign: 'center'
                                        }}>
                                            <ListItemText
                                                primary={`Número de documentos registrados: ${summaryClientCSV.numDocumentsInserted}`}
                                                primaryTypographyProps={{
                                                    style: {
                                                        color: "green",
                                                        fontWeight: "bold",
                                                        fontSize: "14px",
                                                    },
                                                }}
                                            />
                                            <ListItemText
                                                primary={`Número de documento actualizados: ${summaryClientCSV.numDocumentsUpdated}`}
                                                primaryTypographyProps={{
                                                    style: {
                                                        color: "green",
                                                        fontWeight: "bold",
                                                        fontSize: "14px",
                                                    },
                                                }}
                                            />
                                        </ListItem>
                                        <Divider variant="middle" />
                                        <ListItem>
                                            <ListItemText
                                                primary={`Documentos con errores encontrados`}
                                                primaryTypographyProps={{
                                                    style: {
                                                        color: "#BB3E51",
                                                        fontWeight: "bold",
                                                        fontSize: "17px",
                                                    },
                                                }}
                                            />
                                        </ListItem>
                                        <Typography component="ul" sx={{ margin: 0, padding: 0 }}>
                                            {summaryClientCSV.detailSummary.filter(d => !d.success).map((detail, index) => (
                                                <ListItem
                                                    key={index}
                                                    dense
                                                    sx={{ padding: "2px 0", marginLeft: "20px" }}
                                                >
                                                    <Box sx={{ display: "flex", width: "100%", gap: "4px" }}>
                                                        <ListItemText
                                                            style={{
                                                                color: "#BB3E51",
                                                                textAlign: "justify",
                                                                flex: 2,
                                                            }}
                                                            primary={<span dangerouslySetInnerHTML={{ __html: detail.message }} />}
                                                        />
                                                    </Box>
                                                </ListItem>
                                            ))}
                                        </Typography>
                                    </List>
                                </Box>
                            </React.Fragment>
                        )}


                    </Box>
                </Box>
                <Snackbar open={alert.open}
                    autoHideDuration={7000}
                    onClose={() => setAlert({ ...alert, open: false })}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                    <Alert variant="filled"
                        onClose={() => setAlert({ ...alert, open: false })}
                        severity={alert.severity}>
                        {alert.message}
                    </Alert>
                </Snackbar>
            </Box>
        </Dashboard>
    )
}
