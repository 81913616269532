export enum PATHS {
    CATALOGO_PATH = '/catalogo',
    TIPO_DOCUMENTO_PATH = '/tipo-documento',
    GENERO_PATH = '/genero',
    EMPRESA_PATH = '/empresa',
    CONCEPTO_PATH = '/concepto',
    TIPO_CLIENTE_PATH = '/tipo-cliente',
    MARATHON_PATH = '/marathon',
    CLIENTE_PATH = '/cliente',
    CAMPAÑA_PATH = '/campaña',
    AUDITORIA_PATH = '/auditoria',
    USUARIO_PATH = '/usuario',
    CATEGORIA_PATH = '/categoria',
    EQUIPO_PATH = '/equipo',
    PANELAPTC_PATH = '/panelAPTC',
}
