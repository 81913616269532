import { MARCAS } from 'const'
import { EMPRESA_ID } from 'enums'
import { ITypeAPTC } from 'types'

export const getMarcaComercial = (empresa: number): string => {
    if (empresa === EMPRESA_ID.SUPERDEPORTE) {
        return MARCAS.SUPERDEPORTE_MARCA
    } else if (empresa === EMPRESA_ID.EQUINOX) {
        return MARCAS.EQUINOX_MARCA
    } else if (empresa === EMPRESA_ID.REEBOK) {
        return MARCAS.MEDEPORT_REEBOK_MARCA
    } else if (empresa === EMPRESA_ID.ADIDAS) {
        return MARCAS.MEDEPORT_ADIDAS_MARCA
    }
    return 'No se encontró información de la marca comercial'
}

export const getTitulo = (type: ITypeAPTC, empresa: number): string => {
    if (type === 'TC') {
        return `Términos y Condiciones - ${getMarcaComercial(empresa)} `
    } else if (type === 'AP') {
        return `Política de Privacidad - ${getMarcaComercial(empresa)}`
    }
    return 'No se encontró información de la marca'
}
