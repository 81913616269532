import { LoadingButton } from '@mui/lab'
import {
    Alert,
    AlertColor,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Icon,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Snackbar,
    TextField,
    Tooltip,
    Typography
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { useAppSelector } from 'app/hooks'
import { DIALOGS_CLIENT } from 'const'
import dayjs from 'dayjs'
import { getCategoriaSelector, getEquipoSelector, getGeneroListSelector, getTipoClienteListSelector, getTipoDocumentoListSelector, getUsuarioSelector } from 'features'
import React, { Fragment } from 'react'
import { updateClienteService } from 'services'
import { IClienteRequest, IClienteResponse, IEditFormError } from 'types'
import { fixDate, validateClientEditForm } from 'utils'

interface Props {
    open: boolean
    cliente: IClienteResponse | undefined
    handleClose: (dialog: string) => void
    handleSetSelectedCliente: (cliente: IClienteResponse) => void
}

export const EditClienteDialog = (props: Props) => {
    const { open, cliente, handleClose, handleSetSelectedCliente } = props
    const [data, setData] = React.useState<IClienteResponse>({} as IClienteResponse)
    const [alert, setAlert] = React.useState<IEditFormError>()
    const [updating, setUpdating] = React.useState<boolean>(false)
    const tipoDocumentoList = useAppSelector(getTipoDocumentoListSelector)
    const categoriaList = useAppSelector(getCategoriaSelector)
    const equipoList = useAppSelector(getEquipoSelector)
    const tipoClienteList = useAppSelector(getTipoClienteListSelector)
    const generoList = useAppSelector(getGeneroListSelector)
    const usuario = useAppSelector(getUsuarioSelector)

    const handleIsMayorEdad = () => {
        const nacimientoDayjs = dayjs(data.fechaNacimiento)
        const age = dayjs().diff(nacimientoDayjs, 'year')
        if (age < 18) {
            return false
        }
        return true
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const actualAlerts = validateClientEditForm(data)

        try {
            if (actualAlerts.length > 0) {
                setAlert(actualAlerts[0])
            } else {
                setAlert(undefined)
                setUpdating(true)
                const clienteRequest: IClienteRequest = {
                    clienteId: data.clienteId,
                    nombres: data.nombres,
                    apellidos: data.apellidos,
                    genero: data.genero,
                    email: data.email,
                    fechaNacimiento: dayjs(data.fechaNacimiento).format('YYYY/MM/DD'),
                    empresa: data.empresa,
                    celular: data.celular,
                    avisoPrivacidad: data.avisoPrivacidad,
                    terminos: data.terminos,
                    esMayorEdad: handleIsMayorEdad(),
                    activo: data.activo,
                    ultimoMotivo: data.ultimoMotivo,
                    tipoCliente: data.tipoCliente ?? null,
                    categoria: data.categoria ?? null,
                    equipo: data.equipo ?? null,
                    observaciones: data.observaciones ?? null,
                    campaña: data.campaña ?? null,
                    emailValid: data.emailValid,
                    phoneValid: data.phoneValid,
                    usuarioId: usuario!.usuarioId,
                }
                const response = await updateClienteService(clienteRequest)
                setUpdating(false)
                handleSetSelectedCliente(response)
                setAlert({ typeError: 'success', key: '', message: 'El registro del cliente ha sido modificado.' })
                handleClose(DIALOGS_CLIENT.EDIT_CLIENTE)
            }
        } catch (error) {
            console.log("Error", error)
        }
    }

    const handleCloseSnackbar = () => {
        setAlert(undefined)
    }

    const changeValueEntity = (key: keyof IClienteResponse, value: IClienteResponse[keyof IClienteResponse]) => {
        setData((prev) => ({ ...prev, [key]: value }))
    }

    const getValueData = (key: keyof IClienteResponse): IClienteResponse[keyof IClienteResponse] => {
        const value = data[key]
        return value ?? ''
    }

    React.useEffect(() => {
        if (cliente !== undefined) setData(cliente)
    }, [cliente])

    return (
        <Fragment>
            {cliente && (
                <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                    <form onSubmit={handleSubmit}>
                        <DialogTitle>Editar Información Cliente</DialogTitle>
                        <DialogContent>
                            <DialogContentText>Toda edición de información será guardada como registro de auditoria.</DialogContentText>
                            <Grid container gap={2} sx={{ mt: 4, display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                                <Grid item xs={5.5}>
                                    <TextField
                                        id="nombres"
                                        name="nombres"
                                        label="Nombres"
                                        variant="outlined"
                                        size="small"
                                        value={getValueData('nombres')}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            changeValueEntity('nombres', event.target.value.toUpperCase())
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5.5}>
                                    <TextField
                                        id="apellidos"
                                        name="apellidos"
                                        label="Apellidos"
                                        variant="outlined"
                                        size="small"
                                        value={getValueData('apellidos')}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            changeValueEntity('apellidos', event.target.value.toUpperCase())
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5.5}>
                                    <TextField
                                        id="tipoDocumento"
                                        name="tipoDocumento"
                                        label="Tipo Documento"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        value={tipoDocumentoList.find((t) => t.codigo === getValueData('tipoDocumento'))?.descripcion}
                                    />
                                </Grid>
                                <Grid item xs={5.5}>
                                    <TextField
                                        id="numDocumento"
                                        name="numDocumento"
                                        label="Número de documento"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        value={getValueData('numDocumento')}
                                    />
                                </Grid>
                                <Grid item xs={5.5}>
                                    <FormControl sx={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                        <RadioGroup
                                            row
                                            sx={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}
                                            value={getValueData('genero').toString()}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                changeValueEntity('genero', event.target.value)
                                            }}
                                        >
                                            {generoList.map((genero) => (
                                                <FormControlLabel
                                                    key={genero.codigo}
                                                    value={genero.codigo}
                                                    control={<Radio />}
                                                    label={<Typography sx={{ fontWeight: '300' }}>{genero.descripcion}</Typography>}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={5.5}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <TextField
                                            sx={{
                                                flex: 1.1,
                                            }}
                                            id="email"
                                            name="email"
                                            label="Email"
                                            variant="outlined"
                                            size="small"
                                            value={getValueData('email')}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                changeValueEntity('email', event.target.value)
                                            }}
                                        />
                                        <FormControl sx={{
                                            flex: 1,
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>

                                            <RadioGroup
                                                row
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                                value={getValueData('emailValid') ? 'valido' : 'invalido'}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    const newValue = event.target.value === 'valido'
                                                    changeValueEntity('emailValid', newValue)
                                                }}
                                            >
                                                <FormControlLabel value="valido" control={<Radio sx={{
                                                    color: '#03C056',
                                                    '&.Mui-checked': {
                                                        color: '#03C056',
                                                    },
                                                }} />} label="Válido" />
                                                <FormControlLabel value="invalido" control={<Radio sx={{
                                                    color: '#C00050',
                                                    '&.Mui-checked': {
                                                        color: '#C00050',
                                                    },
                                                }} />} label="Inválido" />
                                            </RadioGroup>
                                        </FormControl>

                                    </Box>
                                </Grid>
                                <Grid item xs={5.5}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <DatePicker
                                            label="Fecha de nacimiento"
                                            format="YYYY-MM-DD"
                                            slotProps={{ textField: { size: 'small' } }}
                                            value={getValueData('fechaNacimiento') !== '' ? dayjs(getValueData('fechaNacimiento').toString()) : null}
                                            onChange={(newValue) => {
                                                if (newValue !== null) {
                                                    changeValueEntity('fechaNacimiento', newValue.format('YYYY-MM-DD'))
                                                }
                                            }}
                                        />
                                        {!handleIsMayorEdad() && (
                                            <Tooltip title="Es menor de edad">
                                                <Box sx={{ marginLeft: 1 }}>
                                                    <Icon sx={{ fontSize: '27px', color: '#F57C00' }}>warning</Icon>
                                                </Box>
                                            </Tooltip>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item xs={5.5} >
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <TextField
                                            sx={{
                                                flex: 1.1,
                                            }}
                                            id="celular"
                                            name="celular"
                                            label="Celular"
                                            variant="outlined"
                                            size="small"
                                            inputProps={{
                                                maxLength: 10,
                                            }}
                                            value={getValueData('celular')}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                changeValueEntity('celular', event.target.value)
                                            }}
                                        />

                                        <FormControl sx={{
                                            flex: 1,
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>

                                            <RadioGroup
                                                row
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                                value={getValueData('phoneValid') ? 'valido' : 'invalido'}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    const newValue = event.target.value === 'valido'
                                                    changeValueEntity('phoneValid', newValue)
                                                }}
                                            >
                                                <FormControlLabel value="valido" control={<Radio sx={{
                                                    color: '#03C056',
                                                    '&.Mui-checked': {
                                                        color: '#03C056',
                                                    },
                                                }} />} label="Válido" />
                                                <FormControlLabel value="invalido" control={<Radio sx={{
                                                    color: '#C00050',
                                                    '&.Mui-checked': {
                                                        color: '#C00050',
                                                    },
                                                }} />} label="Inválido" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!!getValueData('esMayorEdad')}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        changeValueEntity('esMayorEdad', event.target.checked)
                                                    }}
                                                    disabled
                                                />
                                            }
                                            label={
                                                <Typography sx={{
                                                    fontSize: '14px',
                                                    fontWeight: '300',
                                                    letterSpacing: '0.05em'
                                                }}>Es mayor de edad?</Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!!getValueData('avisoPrivacidad')}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        changeValueEntity('avisoPrivacidad', event.target.checked)
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography sx={{ fontSize: '14px', fontWeight: '300', letterSpacing: '0.05em' }}>
                                                    Aviso de Privacidad
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!!getValueData('terminos')}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        changeValueEntity('terminos', event.target.checked)
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography sx={{ fontSize: '14px', fontWeight: '300', letterSpacing: '0.05em' }}>
                                                    Términos y Condiciones
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!!getValueData('activo')}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        changeValueEntity('activo', event.target.checked)
                                                    }}
                                                />
                                            }
                                            label={<Typography sx={{ fontSize: '14px', fontWeight: '300', letterSpacing: '0.05em' }}>Activo</Typography>}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={5.5}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel style={{ color: '#C4C4C4' }}>Catergoría</InputLabel>
                                        <Select
                                            id="categoria"
                                            name="categoria"
                                            value={getValueData('categoria').toString()}
                                            onChange={(event: SelectChangeEvent) => {
                                                changeValueEntity('categoria', event.target.value)
                                            }}
                                            label="Categoría"
                                            style={{ borderRadius: '0px' }}
                                        >
                                            {categoriaList.map((categoria) => (
                                                <MenuItem key={categoria.codigo} value={categoria.codigo}>
                                                    {`${categoria.codigo}: ${categoria.descripcion}`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={5.5}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel style={{ color: '#C4C4C4' }}>Equipo</InputLabel>
                                        <Select
                                            id="equipo"
                                            name="equipo"
                                            value={getValueData('equipo').toString()}
                                            onChange={(event: SelectChangeEvent) => {
                                                changeValueEntity('equipo', event.target.value)
                                            }}
                                            label="Equipo"
                                            style={{ borderRadius: '0px' }}
                                        >
                                            {equipoList.map((equipo) => (
                                                <MenuItem key={equipo.codigo} value={equipo.codigo}>
                                                    {`${equipo.codigo}: ${equipo.descripcion}`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={5.5}>
                                    <TextField
                                        id="insertado"
                                        name="insertado"
                                        label="Insertado"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        value={(getValueData('insertado') ? fixDate(getValueData('insertado') as string) : '')}
                                    />
                                </Grid>
                                <Grid item xs={5.5}>
                                    <TextField
                                        id="actualizado"
                                        name="actualizado"
                                        label="Actualizado"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        value={(getValueData('actualizado') ? fixDate(getValueData('actualizado') as string) : '')}
                                    />
                                </Grid>

                                <Grid item xs={5.5}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel style={{ color: '#C4C4C4' }}>Tipo de cliente</InputLabel>
                                        <Select
                                            id="tipoCliente"
                                            name="tipoCliente"
                                            value={getValueData('tipoCliente').toString()}
                                            onChange={(event: SelectChangeEvent) => {
                                                changeValueEntity('tipoCliente', event.target.value)
                                            }}
                                            label="Tipo de cliente"
                                            style={{ borderRadius: '0px' }}
                                        >
                                            {tipoClienteList.map((tipoCliente) => (
                                                <MenuItem key={tipoCliente.tipoClienteId} value={tipoCliente.tipoClienteId}>
                                                    {tipoCliente.descripcion}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={5.5}>
                                    <TextField
                                        id="ultimoMotivo"
                                        name="ultimoMotivo"
                                        label="Motivo de Modificación"
                                        variant="outlined"
                                        size="small"
                                        value={getValueData('ultimoMotivo')}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            changeValueEntity('ultimoMotivo', event.target.value)
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={5.5}>
                                    <TextField
                                        id="observaciones"
                                        name="observaciones"
                                        label="Observaciones"
                                        placeholder='Describa sus observaciones'
                                        variant="outlined"
                                        size="small"
                                        multiline
                                        rows={4}
                                        value={getValueData('observaciones')}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            changeValueEntity('observaciones', event.target.value)
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={5.5}>
                                    <TextField
                                        id="campaña"
                                        name="campaña"
                                        label="Campaña"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        value={getValueData('campaña') || ''}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            changeValueEntity('campaña', event.target.value)
                                        }}
                                    />
                                </Grid>

                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <LoadingButton
                                type="submit"
                                size="small"
                                loading={updating}
                                disabled={updating}
                                sx={{
                                    height: '40px', fontSize: '14px',
                                    '& .MuiLoadingButton-loadingIndicator': {
                                        color: 'white',
                                    },
                                }}>
                                MODIFICAR
                            </ LoadingButton>
                            <Button size="small" onClick={() => handleClose(DIALOGS_CLIENT.EDIT_CLIENTE)} sx={{ height: '40px', fontSize: '14px' }}>
                                CANCELAR
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            )}
            <Snackbar open={alert !== undefined} onClose={handleCloseSnackbar}>
                <Alert severity={alert?.typeError as AlertColor} onClose={handleCloseSnackbar}>
                    {alert?.message}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}
