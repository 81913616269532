import React from 'react'

type Hook<T> = {
    loading: T[]
    handleChangeLoading: (typeLoad: T) => void
    handleCloseLoad: (typeLoad: T) => void
}

export const useLoading = <T>(): Hook<T> => {
    const [loading, setLoading] = React.useState<T[]>([])

    const handleChangeLoading = (typeLoad: T) => {
        setLoading((prev) => {
            const existLoad = prev.some((item) => item === typeLoad)
            if (!existLoad) {
                return [...prev, typeLoad]
            }
            return prev
        })
    }

    const handleCloseLoad = (typeLoad: T) => {
        setLoading((prev) => prev.filter((load) => load !== typeLoad))
    }

    return {
        loading,
        handleChangeLoading,
        handleCloseLoad,
    }
}
