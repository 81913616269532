import axios, { AxiosError } from 'axios'
import { LOCALSTORAGE_KEYS, PATHS } from 'const'
import { AuditoriaResponse, ICheckedItems, IClienteRequest, IClienteResponse, PaginationResponse } from 'types'
import { wrapUploadCSV } from 'utils'

type IHeaders = {
    Authorization: string
    Token: string
}
const getItem = (key: string): any | undefined => {
    const value = localStorage.getItem(key)
    try {
        if (value) {
            const json = JSON.parse(value)
            return json
        }
    } catch (error) {
        return undefined
    }
}
const getHeaders = (): IHeaders => {
    const token = getItem(LOCALSTORAGE_KEYS.MARATHON) ?? ''
    return {
        Authorization: `Bearer ${process.env.REACT_APP_SECURITY_API_KEY}`,
        Token: token,
    }
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    timeout: 900000,
})

const setAuthHeaders = (Authorization: string, Token: string) => {
    instance.defaults.headers.common['Authorization'] = Authorization
    instance.defaults.headers.common['Token'] = Token
}

export const getClienteListService = async (limit: number, page: number, empresa: number, filtros: ICheckedItems, busqueda?: string) => {
    try {
        const { Authorization, Token } = getHeaders()
        setAuthHeaders(Authorization, Token)
        const response = await instance.get(PATHS.CLIENTE_PATH, {
            params: {
                limit,
                page,
                empresaId: empresa,
                busqueda,
                //? Extraemos solo los que estan activos y mandamos como parametros
                ...Object.fromEntries(
                    Object.entries(filtros)
                        .filter(([key, value]) => value)
                        .map(([key]) => [key, true])
                ),
            },
        })
        return response.data as PaginationResponse
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}

export const updateClienteService = async (cliente: IClienteRequest) => {
    try {
        const { Authorization, Token } = getHeaders()
        setAuthHeaders(Authorization, Token)
        const response = (await instance.put(PATHS.CLIENTE_PATH, cliente)).data
        return response as IClienteResponse
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}

export const getAuditoriaByClienteListService = async (clienteId: number) => {
    try {
        const { Authorization, Token } = getHeaders()
        setAuthHeaders(Authorization, Token)
        const response = await instance.get(PATHS.AUDITORIA_PATH, { params: { clienteId } })
        return response.data as AuditoriaResponse[]
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}

export const getClienteReporteService = async (empresa: number, filtros: ICheckedItems, busqueda?: string) => {
    try {
        const response = await instance.get(PATHS.CLIENTE_PATH + '/reporte', {
            params: {
                empresaId: empresa,
                busqueda,
                //? Extraemos solo los que estan activos y mandamos como parametros
                ...Object.fromEntries(
                    Object.entries(filtros)
                        .filter(([key, value]) => value)
                        .map(([key]) => [key, true])
                ),
            },
            responseType: 'blob',
        })
        return response.data
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}

export const sendEmailClienteService = async (certificado: string, empresa: number, emails: string[]) => {
    try {
        const { Authorization, Token } = getHeaders()
        setAuthHeaders(Authorization, Token)
        const response = await instance.post(PATHS.CLIENTE_PATH + '/email', { certificado, empresa, emails })
        return response.data
    } catch (error) {
        throw new Error((error as AxiosError).message)
    }
}

export const uploadPlantillaCSVService = async (file: File, empresaId: number, usuarioId: number) => {
    const { Authorization, Token } = getHeaders()
    setAuthHeaders(Authorization, Token)
    const formData = wrapUploadCSV(file, empresaId, usuarioId)
    try {
        const response = await instance.post(PATHS.CLIENTE_PATH + '/upload', formData)
        return response
    } catch (error: any) {
        throw new Error(error.response?.data?.message ?? error.message)
    }
}
