import { Box, Divider, Drawer, Icon, IconButton, ListItemButton, Tooltip, Typography } from '@mui/material'
import { OPTIONS } from 'const'
import { useAuth } from 'hooks'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from 'routes'
import { COLORS } from 'styles'

type Props = {
    children?: React.ReactNode
}

export const NavDrawer: React.FC<Props> = ({ children }) => {
    const [open, setOpen] = React.useState<boolean>()
    const handleDrawerOpen = () => setOpen(true)
    const handleDrawerClose = () => setOpen(false)
    const location = useLocation()
    const navigate = useNavigate()
    const { usuario, handleLogout, findUserByToken, token, isAuthenticated } = useAuth()

    const isSelectedPath = (path: string) => {
        return location.pathname === ROUTES.root + ROUTES.private + ROUTES.root + path
    }

    const validOptions = () => {
        const validOptions = []

        if (usuario?.permisoUsuarios) {
            validOptions.push(OPTIONS[0])
        }

        if (usuario?.permisoSuperdeporte ||
            usuario?.permisoMedeportAdidas ||
            usuario?.permisoMedeportReebok ||
            usuario?.permisoEquinox) {
            validOptions.push(OPTIONS[1])
            validOptions.push(OPTIONS[2])
            validOptions.push(OPTIONS[3])
            validOptions.push(OPTIONS[4])
        }
        validOptions.push(OPTIONS[5])
        return validOptions
    }

    const handleUser = async () => {
        await findUserByToken()
    }

    const validPath = () => {
        if (!usuario?.permisoUsuarios) {
            navigate(ROUTES.root + ROUTES.private + ROUTES.root + ROUTES.clientes)
        }
    }

    React.useEffect(() => {
        try {
            handleUser()
            validPath()
        } catch (error: any) {
            console.log('ERROR MESSAGE', error.message)
        }
    }, [token])

    React.useEffect(() => {
        if (!isAuthenticated()) {
            navigate(ROUTES.root + ROUTES.login)
        }
    }, [isAuthenticated, navigate])


    React.useEffect(() => {
        window.innerWidth > 769 ? setOpen(true) : setOpen(false)
    }, [])

    window.addEventListener('resize', () => {
        window.innerWidth > 769 ? setOpen(true) : setOpen(false)
    })

    window.addEventListener('load', () => {
        window.innerWidth > 769 ? setOpen(true) : setOpen(false)
    })

    return (
        <>
            <React.Fragment>
                {(!open) && (
                    <Box
                        sx={{
                            width: 60,
                            backgroundColor: COLORS.secondary.main,
                            height: '100vh',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            py: 2,
                            position: 'fixed',
                            overflowX: 'hidden',
                            top: 0,
                            left: 0,
                            zIndex: 1200,
                        }}
                    >
                        <IconButton sx={{ color: COLORS.background.main }} onClick={handleDrawerOpen}>
                            <Icon>menu</Icon>
                        </IconButton>
                        <Divider sx={{ color: COLORS.background.main, mt: 2, height: 4, width: '100%' }} />
                        <Box sx={{ flexGrow: 1, py: 1, px: 2 }}>
                            {validOptions().map((nav) => (
                                <Tooltip arrow key={nav.path} title={<Typography fontSize={14}>{nav.nombre}</Typography>} placement="right">
                                    <ListItemButton
                                        sx={{ display: 'flex', justifyContent: 'center', fontSize: '23px', mt: 1 }}
                                        selected={isSelectedPath(nav.path)}
                                        onClick={(e) => {
                                            if (nav.nombre === OPTIONS[5].nombre) {
                                                handleLogout()
                                                return
                                            }
                                            navigate(nav.path)
                                        }}
                                    >
                                        <Icon style={{ color: isSelectedPath(nav.path) ? COLORS.background.main : COLORS.primary.main }}>{nav.logo}</Icon>
                                    </ListItemButton>
                                </Tooltip>
                            ))}
                        </Box>
                    </Box>
                )}
                <Drawer variant="persistent" anchor="left" open={open}>
                    <Box
                        sx={{
                            width: 150,
                            backgroundColor: COLORS.secondary.main,
                            height: '100vh',
                            display: 'flex',
                            overflowX: 'hidden',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                py: 1.4,
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    variant="button"
                                    sx={{ color: COLORS.background.main, fontWeight: 900, letterSpacing: '0.5px', fontSize: '16', flexGrow: 1, pl: 2 }}
                                >
                                    DASHBOARD
                                </Typography>
                                <IconButton onClick={handleDrawerClose} sx={{ mr: 1 }}>
                                    <Icon fontSize="small">chevron_left</Icon>
                                </IconButton>
                            </Box>
                            <Divider sx={{ color: COLORS.background.main, mt: 2, height: 4, width: '100%' }} />
                            <Box sx={{ width: '100%', py: 1 }}>
                                {validOptions().map((nav) => (
                                    <ListItemButton
                                        sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 1 }}
                                        selected={isSelectedPath(nav.path)}
                                        key={nav.path}
                                        onClick={(e) => {
                                            if (nav.nombre === OPTIONS[5].nombre) {
                                                handleLogout()
                                                return
                                            }
                                            navigate(nav.path)
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                            }}
                                        >
                                            <Icon style={{ color: isSelectedPath(nav.path) ? COLORS.background.main : COLORS.primary.main }}>
                                                {nav.logo}
                                            </Icon>
                                            <Typography sx={{ ml: 1.4 }}>{nav.nombre}</Typography>
                                        </Box>
                                    </ListItemButton>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Drawer>
            </React.Fragment>
            <Box sx={{ width: '100%', height: '100vh', overflowX: 'hidden', display: 'flex', ml: !open ? '50px' : '135px' }}>{children}</Box>
        </>
    )
}
